import { useState, useEffect, useMemo, useRef } from "react";
import "./styles.scss";
import { addIcon } from "../../../assets/icons";
import ProductTable from "./ProductTable";
import AddProductModal from "./AddProductModal";
import Modal from "../../Basic/Modal";
import { toast, ToastContainer } from "react-toastify";
import { toastNotifications } from "../../../helpers";
import {
  bill_history_db,
  fetchInventoryToDB,
  inventory_db,
} from "../../../data/indexedDB";
import { randomString } from "../../../helpers/randomString";
import {
  calculateSubTotal,
  calculateTotalAmount,
  calculateTotalDiscount,
  calculateTotalTax,
  calculationBill,
} from "./billingCalculations";
import { useHistory, useLocation } from "react-router-dom";
import { getDifferenceInArrays } from "../../../helpers/getDifferenceInArrays";
import {
  BILL_VIEW_TYPES,
  getLocalStorage,
  PAYMENTMETHOD,
  PAYMENT_STATUS,
  STORAGE_KEYS,
} from "../../../helpers/common";
import {
  postMethod,
  putMethod,
  getMethod,
  deleteMethod,
} from "../../../api/basic_configs/appService";
import { API_END_POINTS, API_URLS_PATH } from "../../../api/urlsConfig";
import { PDFGenerate } from "../../Basic/Pdfgenerate/pdfgenerate";
import { Button } from "react-bootstrap";
import { ROUTES_PATH } from "../../../routes";
import useRazorPayHook from "../../../hooks/razorPayhook";
import Select from "react-select";
import {
  calculateoverallItemTax,
  calculatePerItemTax,
  calculateTotal,
} from "./ProductTable";
import PayWithUpi from "./PayWithUpi";
import PayWithCcDc from "./PayWithCcDc";

export default function (props: any) {
  interface Product {
    _id: string;
    batch_no: string;
    current_qty: number;
    customer_pay: number;
    expiry_date: string;
  }

  interface SelectedOption {
    value: string;
    label: string;
  }
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const useLocationHook = useLocation();
  const queryParams = new URLSearchParams(useLocationHook.search);
  const myParam = queryParams.get("type");
  const { billData, billViewType } = props;
  const { state } = useLocation<any>();
  const resumingData = state?.billData;
  const [inventoryId, setInventoryId] = useState("");
  const [reorderinventoryId, setreorderInventoryId] = useState("");
  const [upiMsgString, setUpiMsgString] = useState("");
  const [ccdcMsgString, setCcdcMsgString] = useState("");
  const [tdsRate, setTdsRate] = useState<any>();
  const [tcsCgstRate, setTcsCgstRate] = useState();
  const [tcsSgstRate, setTcsSgstRate] = useState();
  const [gstRate, setGstRate] = useState();
  const [hsnCode, setHsnCode] = useState();
  const [isCafeIsStoreTdsStore, setIsCafeIsStoreTdsStore] = useState();
  const [isCafeIsStoreTdsCafe, setIsCafeIsStoreTdsCafe] = useState();
  const [isCafeIsStoreTcsCgstStore, setIsCafeIsStoreTcsCgstStore] = useState();
  const [isCafeIsStoreTcsCgstCafe, setIsCafeIsStoreTcsCgstCafe] = useState();
  const [isCafeIsStoreTcsSgstStore, setIsCafeIsStoreTcsSgstStore] = useState();
  const [isCafeIsStoreTcsSgstCafe, setIsCafeIsStoreTcsSgstCafe] = useState();
  const [inVoiceNumber, setinVoiceNumber] = useState("");
  const [storeDetails, setStoreDetails] = useState<any>([]);
  const [refundComment, setRefundComment] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [paymentInitiate, setPaymentInitiated] = useState({
    UPI: "",
    "CC/DC": "",
  });
  const [paymentStatus, setPaymentStatus] = useState<any>({
    UPI: "",
    "CC/DC": "",
  });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [typeOfMode, setTypeOfMode] = useState("");
  const [invoiceNo, setInvoiceNo] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.invoice_info?.invoice_number
        : randomString(5);
    } else {
      return billData.invoice_info.invoice_number;
    }
  });
  const [trasactionId, setTrasactionId] = useState(
    localStorage.getItem("deviceId") || ""
  );
  const [trasactionModal, setTrasactionModal] = useState(false);
  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );

  const [customerName, setCustomerName] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.customer_details?.customer_name
        : "";
    } else {
      return billData.customerName;
    }
  });

  const [customerContact, setCustomerContact] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.customer_details?.mobile_number
        : "";
    } else {
      return billData.contactNo;
    }
  });

  const [customerAddress, setCustomerAddress] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.customerAdd;
    }
  });

  const [internalNote, setInternalNote] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.internalNotes;
    }
  });

  const [publicNote, setPublicNote] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.publicNotes;
    }
  });
  const [isGSTAvailable, setIsGSTAvailable] = useState(
    billData?.customer_details?.gst ? true : false
  );

  const [isHomeDelivery, setIsHomeDelivery] = useState(
    billData?.customer_details?.address ? true : false
  );
  const [customerGST, setCustomerGST] = useState("");
  const [newStoreDetails, setNewStoreDetails] = useState();
  const [locDetails, setLocDetails] = useState();
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [productModalHeader, setProductModalHeader] = useState("");
  const [isProductSearchable, setIsProductSearchable] = useState(false);
  const [cashIn, setCashIn] = useState(null as any);
  const [calcTrue, setCalcTrue] = useState(true);
  const [toBePaid, setToBePaid] = useState(null as any);
  const [deletedData, setDeletedData] = useState([]);
  const [totalCalculation, setTotalCalculation] = useState<any>({
    totalAmount: 0,
    totalGstAmount: 0,
    subTotal: 0,
  });
  const [generatedCcdcToken, setGeneratedCcdcToken] = useState<any>();
  const [generatedUpiToken, setGeneratedUpiToken] = useState<any>();
  const [amountPaid, setAmountPaid] = useState(false);
  const [upiFlag, setUpiFlag] = useState(false);
  const [qrImage, setQRImage] = useState();
  const [paymentObj, setPaymentObj] = useState({} as any);
  const [doublePaymentError, setDoublePaymentError] = useState(false);
  const [multiplePaymentError, setMultiplePaymentError] = useState(false);
  const [addedReorderStockList, setAddedReorderStockList] = useState<any>([]);
  const merchatId = API_URLS_PATH.REACT_APP_PAYMENT_MERCHANT_ID;
  const userInfoDetails = getLocalStorage(STORAGE_KEYS.USER_INFO);

  useEffect(() => {
    fetchStoreLocDetails();
    fetchTdsTcsRates();
    fetchGstRates();
  }, []);

  const fetchTdsTcsRates = async () => {
    try {
      const url = API_END_POINTS.FETCH_TDS_TCS_DETAILS;
      const response = await getMethod(url);
      const data = response?.data;

      if (data?.length > 0) {
        data?.map((ele: any) => {
          if (ele?.title == "TDS on IT") {
            setTdsRate(ele?.rate);
            setIsCafeIsStoreTdsStore(ele?.isStore);
            setIsCafeIsStoreTdsCafe(ele?.isCafe);
          }
          if (ele?.title == "TCS on GST(CGST)") {
            setTcsCgstRate(ele?.rate);
            setIsCafeIsStoreTcsCgstStore(ele?.isStore);
            setIsCafeIsStoreTcsCgstCafe(ele?.isCafe);
          }
          if (ele?.title == "TCS on GST(SGST)") {
            setTcsSgstRate(ele?.rate);
            setIsCafeIsStoreTcsSgstStore(ele?.isStore);
            setIsCafeIsStoreTcsSgstCafe(ele?.isCafe);
          }
        });
      }
      const gstRateUrl = API_END_POINTS.FETCH_GST_RATE;
      const gstRateRes = await getMethod(gstRateUrl);
      if (gstRateRes?.data?.lenght > 0) {
        const filteredRate = gstRateRes?.filter((ele: any) => {
          return ele?.title == "Service Charges";
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchGstRates = async () => {
    try {
      const gstRateUrl = API_END_POINTS.FETCH_GST_RATE;
      const gstRateRes = await getMethod(gstRateUrl);
      if (gstRateRes?.data?.length > 0) {
        const filteredRate = gstRateRes?.data?.filter((ele: any) => {
          return ele?.title == "Service Charges";
        });
        setGstRate(filteredRate[0]?.rate);
        setHsnCode(filteredRate[0]?.hsnCode);
        localStorage.setItem("hsn", filteredRate[0]?.hsnCode);
      }
    } catch (error) {
      throw error;
    }
  };

  const generateHashToken = async (type: any, amount: any) => {
    try {
      const payloadObj = {
        consumerData: {
          merchantId: merchatId,
          consumerId: userInfoDetails.sub,
        },
        totalAmount: amount?.toString(),
        deviceId: "WEBSH2",
      };
      const response = await postMethod(
        API_END_POINTS.GENERATE_HASH,
        payloadObj
      );
      const data = response?.data;
      if (type == "upi") {
        setGeneratedUpiToken(data);
      } else {
        setGeneratedCcdcToken(data);
      }
    } catch (error) {
      throw error;
    }
  };

  const {
    upiPaymentSuccess,
    ccPaymentSuccess,
    setUpiPaymentSuccess,
    setCcPaymentSuccess,
  } = useRazorPayHook();

  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [addedproductList, setAddedProductList] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined ? resumingData?.add_product : billData;
    } else {
      return billData.add_product
        ? billData.add_product
        : billData.productsDetail.addedProducts;
    }
  });
  const [inventoryListData, setInventoryListData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [isInventoryListLoading, setIsInventoryListLoading] = useState(true);
  const [stateAbbreviation, setStateAbbreviation] = useState("");
  const [productSelectedFromItemCode, setProductSelectedFromItemCode] =
    useState({});
  const [itemCode, setItemCode] = useState("");

  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);
  const loginType = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.LOGIN_TYPE);
  }, []);

  const fetchStoreLocDetails = async () => {
    try {
      const url = API_END_POINTS.FETCH_LOC_STORE_DETAILS?.replace(
        ":login_id",
        details?.registeration_id
      );
      const response = await getMethod(url);
      const data = response?.data;
      setLocDetails(data?.location);
      setNewStoreDetails(data?.store_details);
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      const errorMessage = errorResponse?.error?.error_description;
      toastNotifications.error(errorMessage);
    }
  };
  const deleteBillDetails = async (id: any) => {
    try {
      const url = API_END_POINTS.BILL_DETAILS_DELETE?.replace(":order_id", id);
      await deleteMethod(url);
    } catch (error) {
      throw error;
    }
  };
  // new state
  const [amounts, setAmounts] = useState<any>({
    Cash: "",
    UPI: "",
    CC_DC: "",
  });
  const [BillCreated, setBillCreated] = useState(false);

  const [createBill, setCreateBill] = useState<any>({
    public_note: "",
    internal_note: "",
    mobile_number: "",
    invoice_number: "",
    invoice_date: new Date().toString(),
    isGstChecked: false,
    isHomeDeliveryChecked: false,
    customer_name: "",
    gst: "",
    address: "",
    razorpay_payment_id: "",
    razorpay_order_id: "",
    // payment_method: "",
    list_of_paymentModes: [],
    payment_status: "",
    sub_total: 0,
    tax: 0,
    total_amount: 0,
    store_id: "",
    store_name: "",
    seller_id: "",
    discount: 0,
    hsn: "",
    storePay: 0,
    bill_date: "",
    add_product: [],
    nafed_commission: "",
    nafed_percentage: "",
    cafeDiscountedPrice: "",
    totalAmt: 0,
    batch_no: "",
  });
  const [paymentSuccessResult, setPaymentSuccessResult] = useState({
    upiURNPOSMachine: "",
    ccdcURNPOSMachine: "",
    posInvoiceNos: [
      {
        "POS-UPI": "",
      },
      {
        "POS-CC_DC": "",
      },
    ],
  });
  const totalAmountCalc = parseFloat(
    calculateoverallItemTax(createBill.add_product, isCafe)
  );
  // payment status
  const payment_status_ref = useRef("");
  // navigate
  const navigate = useHistory();
  // location
  const location = useLocation<any>();

  useEffect(() => {
    if (paymentMethod.length == 1 && paymentMethod.includes("UPI")) {
      generateHashToken(
        "upi",
        calculateoverallItemTax(createBill.add_product, isCafe)
      );
    } else if (paymentMethod.length == 1 && paymentMethod.includes("CC/DC")) {
      generateHashToken(
        "ccdc",
        calculateoverallItemTax(createBill.add_product, isCafe)
      );
    }
  }, [paymentMethod]);

  useEffect(() => {
    // check wether products contains or not
    let totalAmount = 0,
      totalGstAmount = 0,
      subTotal = 0;
    // if we have products calculate the total
    if (createBill.add_product.length > 0) {
      const {
        totalAmount: ta,
        totalGstAmount: tgst,
        subTotal: st,
      } = calculationBill(createBill.add_product);
      totalAmount = ta;
      totalGstAmount = tgst;
      subTotal = st;
      setCreateBill({
        ...createBill,
        sub_total: subTotal,
        total_amount: Number(totalAmount),
        tax: totalGstAmount,
      });
    }
  }, [createBill.add_product]);

  // useEffect(() => {
  //   getPaymentKeys();
  // }, []);

  useEffect(() => {
    if (BillCreated === true) {
      const timeoutId = setTimeout(() => {
        getCountOfBills();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [BillCreated]);

  const getPaymentKeys = async () => {
    try {
      const paymentkeys = await getMethod(API_END_POINTS.GET_PAYMENT_KEYS);
      const { data = [] } = paymentkeys;
      const filterPayment =
        data &&
        data.length &&
        data.filter((item: any) => item.is_default === true);
      if (filterPayment && filterPayment.length) {
        setPaymentObj(filterPayment[0]);
      }
    } catch (error) {}
  };
  const callgetstoreAbbreviationAPI = async () => {
    try {
      let url = API_END_POINTS.GET_STATE_ABBREVIATION.replace(
        ":stateName",
        details.location?.state
      );
      const response = await getMethod(url);
      if (response?.data) {
        setStateAbbreviation(response.data?.[0]?.state_name_abbrevation);
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      const errorMessage = errorResponse?.error?.error_description;
      toastNotifications.error(errorMessage);
    }
  };
  useEffect(() => {
    callgetstoreAbbreviationAPI();
  }, [details]);

  useEffect(() => {
    if (
      billViewType == BILL_VIEW_TYPES.BILL_HISTORY ||
      Object.keys(billData).length > 0 ||
      (resumingData ? Object.keys(resumingData).length > 0 : false)
    ) {
      // update the bill state
      const data = resumingData ? resumingData : billData;
      const viewData = {
        public_note: data.public_note,
        internal_note: data.internal_note,
        bill_date: data.bill_date,
        discount: data.discount,
        hsn: data?.hsn,
        nafed_commission: data?.nafed_commission,
        nafed_percentage: data?.nafed_percentage,
        cafeDiscountedPrice: data?.cafeDiscountedPrice,
        storePay: data?.storePay,
        payment_method: data.payment_method,
        payment_status: data.payment_status,
        store_id: data.store_id,
        seller_id: data.seller_id,
        sub_total: data.sub_total,
        tax: data.tax,
        total_amount: Number(data.total_amount),
        invoice_number: data.invoice_info.invoice_number,
        invoice_date: new Date(data.invoice_info.invoice_date).toString(),
        customer_name: data.customer_details.customer_name,
        mobile_number: data.customer_details.mobile_number,
        gst: data.customer_details.gst,
        address: data.customer_details.address,
        isGstChecked: data.customer_details.gst != "" ? true : false,
        isHomeDeliveryChecked:
          data.customer_details.address != "" ? true : false,
        add_product: data.add_product,
        razorpay_payment_id: details.razorpay_payment_id,
        razorpay_order_id: details.razorpay_order_id,
      };

      setCreateBill({
        ...createBill,
        ...viewData,
      });
    }
  }, [billData]);

  const putUpdateBill = async (sendPayload: any) => {
    try {
      const url = API_END_POINTS.UPDATE_POS_CREATE_BILL.replace(
        ":invoice_id",
        resumingData ? resumingData._id : ""
      );
      const response = await putMethod(url, sendPayload);
      toast.success("Success", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      throw error;
    }
  };

  const resetOrClearBill = () => {
    payment_status_ref.current = "";
    setCashIn(null);
    setCreateBill({
      public_note: "",
      internal_note: "",
      mobile_number: "",
      invoice_number: "",
      invoice_date: new Date().toString(),
      isGstChecked: false,
      isHomeDeliveryChecked: false,
      customer_name: "",
      gst: "",
      address: "",
      razorpay_order_id: "",
      razorpay_payment_id: "",
      // payment_method: "",
      list_of_paymentModes: [],
      payment_status: "",
      sub_total: 0,
      storePay: 0,
      nafed_commission: "",
      nafed_percentage: "",
      cafeDiscountedPrice: "",
      tax: 0,
      total_amount: 0,
      store_id: "",
      store_name: "",
      seller_id: "",
      discount: 0,
      hsn: "",
      bill_date: "",
      add_product: [],
      totalAmt: 0,
      batch_no: "",
    });

    navigate.replace(ROUTES_PATH.CREATE_BILL, {});
  };

  const onClearBillHandler = () => {
    resetOrClearBill();
    setAmountPaid(false);
    setPaymentMethod([]);
    setAddedProductList([]);
    setAmounts({});
    setItemCode("");
    setUpiPaymentSuccess(false);
    setCcPaymentSuccess(false);
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
    toastNotifications.warning("Bill Cleared");
  };

  const onAddProductButtonHandler = () => {
    setIsAddProductModalOpen(true);
    setProductModalHeader("Add Product");
    setIsProductSearchable(true);
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
  };

  const calculateRefundedItems = () => {
    let data = getDifferenceInArrays(
      addedproductList,
      billData.productsDetail.addedProducts
    );

    return data;
  };

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      const response = await postMethod(API_END_POINTS.INVENTORY_SEARCH, data);
      if (response) {
        if (response.data) {
          setInventoryData(response.data);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    getInventoryListFromDB();
    getProductsFromInventory();
    getCountOfBills();
  }, []);

  const getCountOfBills = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      const url = API_END_POINTS.POS_BILL_COUNT.replace(
        ":store_id",
        data.store_id
      );

      const response = await getMethod(url);
      let countBil: any;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        countBil = response.data?.storeAdminCount;
      } else {
        countBil = response.data?.cafeAdminCount;
      }
      let bill_Invoice_Number = "";
      if (countBil == 0) {
        bill_Invoice_Number = "IN000001";
      } else if (countBil > 0 && countBil < 9) {
        bill_Invoice_Number = "IN00000" + (countBil + 1);
      } else if (countBil > 8 && countBil < 99) {
        bill_Invoice_Number = "IN0000" + (countBil + 1);
      } else if (countBil > 98 && countBil < 999) {
        bill_Invoice_Number = "IN000" + (countBil + 1);
      } else if (countBil > 998 && countBil < 9999) {
        bill_Invoice_Number = "IN00" + (countBil + 1);
      } else if (countBil > 9998 && countBil < 99999) {
        bill_Invoice_Number = "IN0" + (countBil + 1);
      } else {
        bill_Invoice_Number = "IN" + (countBil + 1);
      }
      setinVoiceNumber(bill_Invoice_Number);
    } catch (err) {}
  };

  const calculateClearButtonVisibility = () => {
    if (billData.status == "Done") {
      return false;
    } else return true;
  };

  const calculateSaveButtonVisibility = () => {
    if (billData.status == "Done" || billViewType == "create_bill") {
      return true;
    } else return false;
  };

  const clcProductEditFncAvailabilty = () => {
    if (
      billViewType == "create_bill" ||
      billViewType == "refund_bill" ||
      billData.status == "Pending"
    ) {
      return true;
    } else return false;
  };

  const clcAddProductFncAvailabilty = () => {
    if (billViewType == "create_bill" || billData.status == "Pending") {
      return true;
    } else return false;
  };

  const getInventoryListFromDB = async () => {
    let data = await inventory_db.data.toArray();
    if (data.length != 0) {
      setInventoryListData(data);
      setIsInventoryListLoading(false);
    } else {
      fetchInventoryToDB();

      getInventoryListFromDB();
    }
  };

  const noValidation = () => {
    const mobileRegEx = new RegExp("^[6-9][0-9]{9}$");
    let result = mobileRegEx.test(customerContact);

    return true;
  };

  const nameValidation = () => {
    const nameReg = new RegExp("^[A-Za-z- ]+$");

    let result = nameReg.test(customerName);

    return result;
  };

  const gstValidation = () => {
    if (isGSTAvailable) {
      if (customerGST.length > 0) {
        return true;
      } else return false;
    } else return true;
  };

  const handleCashCalc = () => {
    if (cashIn) {
      setCalcTrue(false);
      setAmountPaid(true);
      const paymentData: any = [{ Cash: cashIn }];
      setCreateBill({ ...createBill, list_of_paymentModes: paymentData });
    }
  };

  const storeInvoiceNoToBackend = async () => {
    let dataArray: any = [];

    createBill.add_product.forEach((item: any) => {
      const mappedCommArr =
        item?.commission_values?.length > 0
          ? item?.commission_values?.map((ele: any) => {
              const commPrice =
                (Number(item?.price) *
                  Number(
                    ele.brokrage_percentage || ele?.brokerage_percentage
                  )) /
                100;
              let tdsAmt = 0;

              const commPriceWithGst =
                commPrice * (Number(item?.commissionGstPercentage) / 100);
              if (isStore) {
                tdsAmt =
                  isCafeIsStoreTdsStore == true
                    ? (commPrice * tdsRate) / 100
                    : 0;
              } else {
                tdsAmt =
                  isCafeIsStoreTdsCafe == true
                    ? (commPrice * tdsRate) / 100
                    : 0;
              }
              const commShare =
                Number(commPrice) + Number(commPriceWithGst) - Number(tdsAmt);

              const commShareWithQty =
                commShare *
                Number(
                  item.quantitySelected ? item.quantitySelected : item.qty
                );
              const filteredifsc =
                item?.state_commissions.length > 0 &&
                item?.state_commissions.filter((element: any) => {
                  return ele?.brokerage_head == element?.brokerage_head;
                });

              return {
                ...ele,
                brokerage_rate:
                  (Number(item?.price) *
                    Number(
                      item.quantitySelected ? item.quantitySelected : item.qty
                    ) *
                    Number(
                      ele.brokrage_percentage || ele?.brokerage_percentage
                    )) /
                    100 +
                  ((Number(item?.price) *
                    Number(
                      item.quantitySelected ? item.quantitySelected : item.qty
                    ) *
                    Number(
                      ele.brokrage_percentage || ele?.brokerage_percentage
                    )) /
                    100) *
                    (Number(item?.commissionGstPercentage) / 100),
                commissionTDSonIT: tdsAmt,
                commissionShare: commShare,
                finalCommissionShare: commShareWithQty,
                ifsc: filteredifsc[0]?.ifsc,
                account_number: filteredifsc[0]?.account_number,
                organisation_bank: filteredifsc[0]?.organisation_bank,
                organisation_name: filteredifsc[0]?.organisation_name,
                brokerage_admin_email: filteredifsc[0]?.brokerage_admin_email,
                mobile: filteredifsc[0]?.mobile,
              };
            })
          : [];
      let tdsStoreAmt;
      const newStorePrice = isStore
        ? Number(item?.storeMarginAmount)
        : Number(item?.discounted_price);
      const newStoreGst = Number(item?.gst);
      const gstStoreAmt = (newStorePrice * newStoreGst) / 100;
      if (isStore) {
        tdsStoreAmt =
          isCafeIsStoreTdsStore == true ? (newStorePrice * tdsRate) / 100 : 0;
      } else {
        tdsStoreAmt =
          isCafeIsStoreTdsCafe == true ? (newStorePrice * tdsRate) / 100 : 0;
      }
      const priceGstStoreAmt = newStorePrice + gstStoreAmt;
      const storeShare = priceGstStoreAmt - tdsStoreAmt;
      const storeShareWithQty =
        storeShare *
        Number(item.quantitySelected ? item.quantitySelected : item.qty);
      const newPrice = Number(item?.price);
      const newGst = Number(item?.gst);
      const gstAmt = (newPrice * newGst) / 100;
      let tdsAmt;
      if (isStore) {
        tdsAmt = isCafeIsStoreTdsStore == true ? (newPrice * tdsRate) / 100 : 0;
      } else {
        tdsAmt = 0;
      }
      const priceGstAmt = newPrice + gstAmt;
      const sellerShare = priceGstAmt - tdsAmt;
      const sellerShareWithQty =
        sellerShare *
        Number(item.quantitySelected ? item.quantitySelected : item.qty);
      const obj = {
        product_name: item.product_name ? item.product_name : item.name,
        seller_id: item.seller_id ? item.seller_id : item.seller_id,
        category: item.category,
        inventory_id: item.inventory_id,
        price_unit: item.price ? item.price : item.unitPrice,
        brand: item.brand ? item.brand : "",
        qty: item.quantitySelected
          ? Number(item.quantitySelected)
          : Number(item.qty),
        variant: "",
        discount: item.discount,
        hsn: item?.hsn,
        nafed_commission: item?.nafed_commission || 0,
        nafed_percentage: item?.nafed_percentage,
        cafeDiscountedPrice: item?.discounted_price,
        storePay: item?.storePay,
        commission_values: mappedCommArr,
        state_commissions: item.state_commissions,
        gst:
          item?.gst != "" && item?.gst != null && item?.gst != undefined
            ? item?.gst
            : 0,
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin"
          ? { inventory_id: item?.parent_invnetory_ref || item?.inventory_id }
          : null),
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "cafeAdmin"
          ? { inventory_id: item.product_ref || item?.inventory_id }
          : null),

        unit: item.unit ? item.unit : "",
        customer_pay: item?.customer_pay,
        price: item?.price ? item?.price : 0,
        seller_name: item?.seller_details?.name_of_organization
          ? item?.seller_details?.name_of_organization
          : "",
        expiry_date: item?.expiry_date ? item?.expiry_date : "",
        seller_details: {
          email_id: item?.seller_details?.email_id
            ? item?.seller_details?.email_id
            : "",
          mobile: item?.seller_details?.mobile,
          name_of_organization: item?.seller_details?.name_of_organization,
          type_of_organization: item?.seller_details?.state,
          state: item?.seller_details?.state,
          city_district: item?.seller_details?.city_district,
          organization_address: item?.seller_details?.organization_address,
          pincode: item?.seller_details?.pincode,
          gst_number: item?.seller_details?.gst_number,
        },
        batch_no: item.batch_no
          ? item.batch_no
          : item?.ReorderedInventoryData?.batch_no,
        reorderedInventoryRef: item.reorderedInventoryRef
          ? item.reorderedInventoryRef
          : item._id,
        current_qty: item.current_qty,
        quantity_details: item.quantity_details,
        product_id: item._id,
        product_ref: item.product_ref,
        disableDecrement: item.disableDecrement,
        disableIncrement: item.disableIncrement,
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin" && {
          sellerStateAbbreviation:
            item?.seller_state_abbreviation || item?.sellerStateAbbreviation,
        }),
        storeStateAbbreviation:
          stateAbbreviation || item?.storeStateAbbreviation,
        total_seller_price_with_qty: item?.total_seller_price_with_qty,
        storeMarginAmount: item?.storeMarginAmount
          ? item?.storeMarginAmount
          : "",
        storeMarginAmountWithQty: item?.storeMarginAmountWithQty
          ? item?.storeMarginAmountWithQty
          : "",
        product_total: item?.product_total
          ? item.product_total || 0
          : Number(item?.customer_pay) *
            (item?.quantitySelected
              ? Number(item.quantitySelected)
              : Number(item.qty)),

        storeTDSonIT: tdsStoreAmt?.toFixed(2),
        storeShare: storeShare?.toFixed(2),
        finalStoreShare: storeShareWithQty?.toFixed(2),
        sellerTDSonIT: tdsAmt?.toFixed(2),
        sellerShare: sellerShare?.toFixed(2),
        finalSellerShare: sellerShareWithQty?.toFixed(2),
        commissionGstPercentage: item?.commissionGstPercentage,
        commissionHSNCode: item?.commissionHSNCode,
        finalAmountForStore:
          item?.customer_pay *
          (item?.quantitySelected
            ? Number(item.quantitySelected)
            : Number(item.qty)),
      };
      dataArray.push(obj);
    });
    let loginTypeVariable;

    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0] == "storeadmin") {
      loginTypeVariable = "storeAdmin";
    } else if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0] == "cafeAdmin") {
      loginTypeVariable = "cafeAdmin";
    }
    const paymentStatus = isChecked
      ? createBill.list_of_paymentModes.map((paymentMode: any) => {
          if (paymentMode.UPI) {
            return { "POS-UPI": paymentMode.UPI };
          } else if (paymentMode.CC_DC) {
            return { "POS-CC_DC": paymentMode.CC_DC };
          }
          return paymentMode;
        })
      : createBill.list_of_paymentModes;
    const sendPayload = [
      {
        invoice_info: {
          invoice_number:
            billViewType == "create_bill" && resumingData != undefined
              ? invoiceNo
              : inVoiceNumber,
          invoice_date: createBill.invoice_date,
        },
        login_type: getLocalStorage(STORAGE_KEYS.LOGIN_TYPE),
        customer_details: {
          customer_name: createBill.customer_name,
          mobile_number: createBill.mobile_number,
          gst: createBill.gst,
          address: createBill.address,
        },
        add_product: dataArray,
        sub_total: createBill?.add_product
          ? calculateTotal(createBill.add_product, isCafe)
          : 0,
        tax: calculatePerItemTax(createBill?.add_product, isCafe),
        discount: createBill.discount,
        hsn: createBill?.hsn,
        nafed_commisiion: createBill?.nafed_commission,
        nafed_percentage: createBill?.nafed_percentage,
        cafeDiscountedPrice: createBill?.cafeDiscountedPrice,
        storePay: createBill?.storePay,
        total_amount: Number(
          calculateoverallItemTax(createBill.add_product, isCafe)
        ),
        list_of_paymentModes:
          payment_status_ref.current != PAYMENT_STATUS.PENDING
            ? paymentStatus
            : [],
        public_note: createBill.public_note,
        internal_note: createBill.internal_note,
        store_id: details.registeration_id,
        store_name: details.name_of_organization,
        seller_id: details.seller_id,
        payment_status: payment_status_ref.current,
        razorpay_order_id: createBill.razorpay_order_id,
        razorpay_payment_id: createBill.razorpay_payment_id,
        orderStore_type: loginTypeVariable,
        storeStateAbbreviation: stateAbbreviation,
        state: details.location.state,
        upiMsg: upiMsgString,
        ccdcMsg: ccdcMsgString,
        store_details: newStoreDetails,
        location_details: locDetails,
        ...paymentSuccessResult,
      },
    ];

    try {
      if (resumingData) {
        await putUpdateBill([
          {
            ...resumingData,
            add_product: dataArray,
            payment_status: payment_status_ref.current,
            list_of_paymentModes:
              payment_status_ref.current != PAYMENT_STATUS.PENDING
                ? paymentStatus
                : [],
            sub_total: createBill?.add_product
              ? calculateTotal(createBill.add_product, isCafe)
              : 0,
            tax: calculatePerItemTax(createBill?.add_product, isCafe),
            discount: createBill.discount,
            total_amount: Number(
              calculateoverallItemTax(createBill.add_product, isCafe)
            ),
            public_note: createBill.public_note,
            internal_note: createBill.internal_note,
            ccdcMsg: ccdcMsgString,
            upiMsg: upiMsgString,
            upiURNPOSMachine: "",
            ccdcURNPOSMachine: "",
            posInvoiceNos: [
              {
                "POS-UPI": "",
              },
              {
                "POS-CC_DC": "",
              },
            ],
            hsn: "",
            nafed_commisiion: "",
            nafed_percentage: "",
            cafeDiscountedPrice: "",
            storePay: 0,
          },
        ]);
      }
      // call post method
      else {
        const response: any = await postMethod(
          API_END_POINTS.CREATE_BILL,
          sendPayload
        );
        if (response.code === 200 && response.data.length > 0) {
          setBillCreated(true);
        }
      }
      // clear the state

      resetOrClearBill();

      if (payment_status_ref.current == PAYMENT_STATUS.PAID) {
        toast.success("Success", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {}
  };
  const handleSaveAction = () => {
    let onlineStatus = navigator.onLine;
    if (onlineStatus) {
      storeInvoiceNoToBackend();
    } else {
      const paymentStatus = isChecked
        ? createBill.list_of_paymentModes.map((paymentMode: any) => {
            if (paymentMode.UPI) {
              return { "POS-UPI": paymentMode.UPI };
            } else if (paymentMode.CC_DC) {
              return { "POS-CC_DC": paymentMode.CC_DC };
            }
            return paymentMode;
          })
        : createBill.list_of_paymentModes;
      let data = {
        invoiceNo: invoiceNo,
        date: new Date().toLocaleString(),
        contactNo: customerContact,
        customerName: customerName,
        customerAdd: customerAddress,
        publicNotes: publicNote,
        internalNotes: internalNote,
        status: payment_status_ref.current,
        list_of_paymentModes:
          payment_status_ref.current != PAYMENT_STATUS.PENDING
            ? paymentStatus
            : "",
        total: calculateTotalAmount(addedproductList),
        productsDetail: {
          subTotal: calculateSubTotal(addedproductList),
          discount: calculateTotalDiscount(addedproductList),

          tax: calculateTotalTax(addedproductList),
          totalAmount: calculateTotalAmount(addedproductList),
          addedProducts: addedproductList,
        },
      };

      switch (true) {
        // if it is create bill
        case billViewType == BILL_VIEW_TYPES.CREATE_BILL:
          // save data in indexDB
          bill_history_db.data.add(data);
          toastNotifications.success("Bill Saved");
          // clear the state
          resetOrClearBill();
          break;
        // bill history
        case billViewType == BILL_VIEW_TYPES.BILL_HISTORY:
          // update the bill history indexDB
          bill_history_db.data
            .update(billData.invoiceNo, data)
            .then(() => {
              toastNotifications.success("Bill Updated");
              setAddedProductList([]);
              setCustomerContact("");
              setPublicNote("");
              setInternalNote("");
              setCustomerGST("");
              setCustomerAddress("");
              setCustomerName("");
              setCashIn("");
              setCalcTrue(false);
              setPaymentMethod([]);
            })
            .catch((error: any) => {});
          break;
      }
    }
  };
  const gstvalidation2 = (data: any) => {
    const gstRegEx = new RegExp(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    );
    let result = gstRegEx.test(data);
    return result;
  };
  const mobile_number_Validation = (data: any) => {
    const mobileRegEx = new RegExp("^[6-9][0-9]{9}$");
    let result = mobileRegEx.test(data);
    return result;
  };
  const [checkboxValues, setCheckboxValues] = useState({
    [PAYMENTMETHOD.CASH]: false,
    [PAYMENTMETHOD.UPI]: false,
    [PAYMENTMETHOD.CC_DC]: false,
  });
  // when user click, confirm button
  const onConfirmHandler = () => {
    // check wether customername and mobile number, data is present or not
    const {
      customer_name,
      isGstChecked,
      isHomeDeliveryChecked,
      mobile_number,
      gst,
      address,
    } = createBill;

    // wether customer name is present or not
    const isCustomerNameEmpty = customer_name == "";
    // // const isMobileNumberEmpty = mobile_number == ""
    const isMobileNumberEmpty = mobile_number_Validation(mobile_number);
    // if it is checked, then check wether gst is empty or not
    // const isGstExist = (isGstChecked ? gst != "" : true)
    const isGstExist = isGstChecked ? gstvalidation2(gst) : true;
    // if it is checked, then check wether address is empty or not
    const isAddressExist = isHomeDeliveryChecked ? address != "" : true;

    // check wether payment method, is selected or not
    const isPaymentMethodSelected = createBill.list_of_paymentModes.length == 0;
    const isSinglePaymentSuccess =
      paymentListLength == 1
        ? cashIn || upiPaymentSuccess || ccPaymentSuccess
        : false;

    const isDoublePaymentSuccess =
      paymentListLength == 2
        ? (amounts.Cash && upiPaymentSuccess) ||
          (amounts.Cash && ccPaymentSuccess) ||
          (ccPaymentSuccess && upiPaymentSuccess)
        : false;
    const isMultiplePaymentSuccess =
      paymentListLength == 3
        ? amounts.Cash && upiPaymentSuccess && ccPaymentSuccess
        : false;
    // // customer name should not be empty

    if (
      !isCustomerNameEmpty &&
      isMobileNumberEmpty &&
      isAddressExist &&
      isGstExist &&
      isChecked
        ? !isPaymentMethodSelected
        : !isPaymentMethodSelected &&
          !isCustomerNameEmpty &&
          isMobileNumberEmpty &&
          isAddressExist &&
          isGstExist &&
          ((paymentListLength == 1 ? isSinglePaymentSuccess : "") ||
            (paymentListLength == 2 ? isDoublePaymentSuccess : "") ||
            (paymentListLength == 3 ? isMultiplePaymentSuccess : ""))
    ) {
      // payment status is paid
      payment_status_ref.current = PAYMENT_STATUS.PAID;

      handleSaveAction();
      toast.success("Bill Generated Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setCheckboxValues({
        [PAYMENTMETHOD.CASH]: false,
        [PAYMENTMETHOD.UPI]: false,
        [PAYMENTMETHOD.CC_DC]: false,
        // Add more payment methods if needed
      });
      setPaymentMethod([]);
      setAmounts([]);
      setAmountPaid(false);
      setUpiPaymentSuccess(false);
      setCcPaymentSuccess(false);
      setItemCode("");
      setAddedReorderStockList([]);
      setSelectedOption(null);
    } else {
      // payment status is pending
      payment_status_ref.current = PAYMENT_STATUS.PENDING;

      let toastMessage = "";
      switch (true) {
        case isCustomerNameEmpty:
          toastMessage = "Enter customer name";
          break;
        // mobile number
        case !isMobileNumberEmpty:
          toastMessage =
            mobile_number == ""
              ? "Enter mobile number"
              : "Enter Valid Mobile Number";
          break;
        // gst field
        // if isGstExist return false, then show toast
        case !isGstExist:
          toastMessage = gst == "" ? "Enter GST" : "Enter Valid GST";
          break;
        // addres field
        case !isAddressExist:
          toastMessage = "Enter address";
          break;
        // payment method not seleceted
        case isPaymentMethodSelected:
          toastMessage = "Choose payment method";
          break;
        case !isChecked &&
          (!isSinglePaymentSuccess ||
            !isDoublePaymentSuccess ||
            !isMultiplePaymentSuccess):
          toastMessage = "Payment is not successful";
          break;
      }
      // show toast message
      toastNotifications.warning(toastMessage);
    }
  };

  // when user click hold button
  const onHoldHandler = () => {
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
    // check wether customer_name and mobile number enter or not
    const iscustomerName = createBill.customer_name != "";
    const isMobileNumberExist = createBill.mobile_number != "";
    if (iscustomerName && isMobileNumberExist) {
      // payment status change to pending
      payment_status_ref.current = PAYMENT_STATUS.PENDING;
      // send data to backend
      handleSaveAction();
      setItemCode("");
      toastNotifications.warning("Pending");
    } else {
      let message = "";
      switch (true) {
        // customer name empty
        case createBill.customer_name == "":
          message = "Enter customer name";
          break;
        // mobile number empty
        case createBill.mobile_number == "":
          message = "Enter mobile number";
          break;
      }
      // show toast message
      toastNotifications.warning(message);
    }
  };
  const validInput = (inputValue: any) => {
    const inputTest = /^\d*\.?\d{0,2}$/.test(inputValue);
    return inputTest;
  };
  const handlePaymentOnchange = (method: any) => {
    if (paymentMethod.includes(method)) {
      setPaymentMethod(paymentMethod.filter((item: any) => item !== method));
    } else {
      setPaymentMethod([...paymentMethod, method]);
    }
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
  };
  useEffect(() => {
    if (addedproductList.length > 0) {
      let newCafeDetails: any[] = [
        {
          store_id: details?.registeration_id,
          customer_name: createBill.customer_name,
        },
      ];
      let newStoreDetails: any[] = [];
      addedproductList.forEach((newProduct: any, index: any) => {
        const existingSeller = newStoreDetails.find(
          (item) => item.seller_id === newProduct.seller_id
        );
        if (!existingSeller) {
          const newData = {
            store_id: index === 0 ? newProduct.store_id : undefined,
            customer_name: createBill.customer_name,
            seller_id: newProduct.seller_id,
            amount: newProduct?.customer_pay,
          };
          newStoreDetails.push(newData);
        }
      });
      const combinedDetails = loginType.includes("storeadmin")
        ? newStoreDetails
        : newCafeDetails;
      // Update storeDetails after the loop is completed
      setStoreDetails(combinedDetails);
    }
  }, [addedproductList]);

  useEffect(() => {
    setStoreDetails((prevStoreDetails: any) =>
      prevStoreDetails.map((prevData: any) => ({
        ...prevData,
        customer_name: createBill.customer_name,
      }))
    );
  }, [createBill.customer_name]);

  const handleProceed = (method: any) => {
    const { customer_name, mobile_number } = createBill;
    const isCustomerNameEmpty = customer_name == "";
    const isMobileNumberEmpty = mobile_number_Validation(mobile_number);
    if (!isCustomerNameEmpty && isMobileNumberEmpty) {
      if (paymentMethod.length == 1 && method == "UPI") {
        setAmounts({ ...amounts, UPI: totalAmountCalc });
      }
      if (paymentMethod.length == 1 && method == "CC/DC") {
        setAmounts({ ...amounts, CC_DC: totalAmountCalc });
      }
    } else {
      let toastMessage = "";
      switch (true) {
        case isCustomerNameEmpty:
          toastMessage = "Enter customer name";
          break;
        // mobile number
        case !isMobileNumberEmpty:
          toastMessage =
            mobile_number == ""
              ? "Enter mobile number"
              : "Enter Valid Mobile Number";
          break;
      }
      // show toast message
      toastNotifications.warning(toastMessage);
    }
  };
  const handlePay = () => {
    const list_of_paymentModes = paymentMethod.map((method: any) => {
      const key = method === "CC/DC" ? "CC_DC" : method;
      return {
        [key]: amounts[key],
      };
    });
    setCreateBill({
      ...createBill,
      list_of_paymentModes: list_of_paymentModes,
    });
    setAmountPaid(true);
  };
  const paymentListLength = createBill.list_of_paymentModes.length;
  const confirmDisabledCondition: boolean = paymentListLength == 0;
  useEffect(() => {
    if (paymentMethod.length == 2) {
      if (amounts.Cash && amounts.UPI) {
        if (
          parseFloat(amounts.Cash) + parseFloat(amounts.UPI) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      } else if (amounts.Cash && amounts.CC_DC) {
        if (
          parseFloat(amounts.Cash) + parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      } else if (amounts.UPI && amounts.CC_DC) {
        if (
          parseFloat(amounts.UPI) + parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      } else if (amounts.UPI) {
        if (parseFloat(amounts.UPI) < totalAmountCalc) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      }
    }
    if (paymentMethod.length == 3) {
      if (amounts.Cash && amounts.UPI && amounts.CC_DC) {
        if (
          parseFloat(amounts.Cash) +
            parseFloat(amounts.UPI) +
            parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setMultiplePaymentError(true);
        } else {
          setMultiplePaymentError(false);
        }
      } else if (amounts.UPI) {
        if (parseFloat(amounts.UPI) < totalAmountCalc) {
          setMultiplePaymentError(true);
        } else {
          setMultiplePaymentError(false);
        }
      }
    }
  }, [amounts]);
  const totalPayment = createBill.list_of_paymentModes
    .reduce((total: any, payment: any) => {
      const paymentValue = parseFloat(Object.values(payment)[0] as string) || 0;
      return total + paymentValue;
    }, 0)
    .toFixed(2);
  const [qtySelected, setQtySelected] = useState();

  const handleBarcodeOrValueRead = async (inputValue: any) => {
    if (inputValue != "" && inputValue?.length > 0) {
      try {
        const data = {
          barCode: inputValue,
          store_id: details.registeration_id,
        };
        const response: any = await postMethod(
          API_END_POINTS.POST_BARCODE,
          data
        );
        if (response) {
          if (response.data) {
            let quantitySelected = response.meta_data.quantity;
            if (quantitySelected === 0) {
              quantitySelected = 1;
              setAddedReorderStockList(response.data[0].ReorderedInventoryData);
            }
            let responseData: any = {
              ...response.data[0],
              quantitySelected: quantitySelected,
            };

            if (isStore) {
              setQtySelected(quantitySelected);
              setInventoryId(response.data[0]?._id);
            } else {
              setCreateBill((data: any) => ({
                ...data,
                add_product: [...data.add_product, responseData],
              }));
            }
          }
        }
        if (response && response.data && response.data.length > 0) {
          const quantitySelected = response.meta_data.quantity;
          const firstselect = response.data[0].ReorderedInventoryData;

          let remainingQuantity = quantitySelected;
          firstselect.forEach((item: any) => {
            if (item.current_qty > 0 && remainingQuantity > 0) {
              let updatedQuantitySelected = item.current_qty;
              if (remainingQuantity < item.current_qty) {
                updatedQuantitySelected = remainingQuantity;
              }
              let reorderedInventoryRef = item._id;
              setreorderInventoryId(reorderedInventoryRef);
              const prevQty = item.current_qty - updatedQuantitySelected;
              const addedQty = updatedQuantitySelected - item.current_qty;
              const storePay = item.storePay;
              const newStorePrice = Number(item?.storeMarginAmount);
              const newStoreGst = Number(item?.gst);
              const gstStoreAmt = (newStorePrice * newStoreGst) / 100;
              let tdsStoreAmt;

              if (isStore) {
                tdsStoreAmt =
                  isCafeIsStoreTdsStore == true
                    ? (newStorePrice * tdsRate) / 100
                    : 0;
              } else {
                tdsStoreAmt =
                  isCafeIsStoreTdsCafe == true
                    ? (newStorePrice * tdsRate) / 100
                    : 0;
              }
              const priceGstStoreAmt = newStorePrice + gstStoreAmt;
              const storeShare = priceGstStoreAmt - tdsStoreAmt;
              const storeShareWithQty = storeShare * updatedQuantitySelected;
              const newPrice = Number(item?.price);
              const newGst = Number(item?.gst);
              const gstAmt = (newPrice * newGst) / 100;
              let tdsAmt;
              if (isStore) {
                tdsAmt =
                  isCafeIsStoreTdsStore == true
                    ? (newPrice * tdsRate) / 100
                    : 0;
              } else {
                tdsAmt = 0;
              }
              const priceGstAmt = newPrice + gstAmt;
              const sellerShare = priceGstAmt - tdsAmt;
              const sellerShareWithQty = sellerShare * updatedQuantitySelected;
              const responseData: any = {
                ...response.data[0].ReorderedInventoryData,
                ...item,
                quantitySelected: updatedQuantitySelected,
                prevQty: prevQty,
                addedQty: addedQty,
                disableDecrement: true,
                disableIncrement: true,
                storePay: storePay,
                reorderedInventoryRef: reorderedInventoryRef,
                parent_invnetory_ref: item?.parent_invnetory_ref,
                total_seller_price_with_qty:
                  Number(item?.finalSellerPrice) *
                  Number(updatedQuantitySelected),
                storeMarginAmount: Number(item?.storeMarginAmount),
                storeMarginAmountWithQty:
                  (Number(item?.storeMarginAmount) +
                    Number(item?.storeMarginAmount) * 0.18) *
                  Number(updatedQuantitySelected),
                product_total:
                  Number(item?.customer_pay) * Number(updatedQuantitySelected),
                storeTDSonIT: tdsStoreAmt?.toFixed(2),
                storeShare: storeShare?.toFixed(2),
                finalStoreShare: storeShareWithQty?.toFixed(2),
                sellerTDSonIT: tdsAmt?.toFixed(2),
                sellerShare: sellerShare?.toFixed(2),
                finalSellerShare: sellerShareWithQty?.toFixed(2),
              };

              const isExistAddProduct = createBill?.add_product?.some(
                (product: any) =>
                  product?.product_name == response.data[0]?.product_name
              );

              if (isExistAddProduct) {
                toast.error("Product is already added!", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                setCreateBill((data: any) => ({
                  ...data,
                  add_product: [...data.add_product, responseData],
                }));
              }
              remainingQuantity -= updatedQuantitySelected;
              if (remainingQuantity <= 0) {
                return;
              }
            }
          });
        }
      } catch (err) {}
    } else {
      setAddedReorderStockList([]);
      setSelectedOption(null);
    }
  };

  const options = addedReorderStockList
    ?.filter((product: Product) => product.current_qty > 0)
    .map((product: Product) => {
      const formattedDate = new Date(product.expiry_date).toLocaleDateString();
      return {
        value: product._id,
        label: `Stock : ${product.batch_no} - Quantity : ${
          product.current_qty
        } - Price : ${product?.customer_pay?.toFixed(
          2
        )} - Expiry Date : ${formattedDate}`,
      };
    });

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected);
    if (selected != null) {
      const filteredSelectedData: any = addedReorderStockList.filter(
        (ele: any) => {
          return ele._id === selected.value;
        }
      );
      const reorderedInventoryRef = selected.value;
      const isExistAddProduct = createBill?.add_product?.some(
        (product: any) =>
          product?._id == selected?.value ||
          product?.product_id == selected?.value
      );
      if (isExistAddProduct) {
        toast.error("Product is already added!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setreorderInventoryId(reorderedInventoryRef);
        const newStorePrice = Number(
          filteredSelectedData[0]?.storeMarginAmount
        );
        const newStoreGst = Number(filteredSelectedData[0]?.gst);
        const gstStoreAmt = (newStorePrice * newStoreGst) / 100;
        let tdsStoreAmt: any;
        if (isStore) {
          tdsStoreAmt =
            isCafeIsStoreTdsStore == true ? (newStorePrice * tdsRate) / 100 : 0;
        } else {
          tdsStoreAmt =
            isCafeIsStoreTdsCafe == true ? (newStorePrice * tdsRate) / 100 : 0;
        }
        const priceGstStoreAmt = newStorePrice + gstStoreAmt;
        const storeShare = priceGstStoreAmt - tdsStoreAmt;
        const storeShareWithQty = storeShare * 1;
        const newPrice = Number(filteredSelectedData[0]?.price);
        const newGst = Number(filteredSelectedData[0]?.gst);
        const gstAmt = (newPrice * newGst) / 100;
        let tdsAmt: any;
        if (isStore) {
          tdsAmt =
            isCafeIsStoreTdsStore == true ? (newPrice * tdsRate) / 100 : 0;
        } else {
          tdsAmt = 0;
        }
        const priceGstAmt = newPrice + gstAmt;
        const sellerShare = priceGstAmt - tdsAmt;
        const sellerShareWithQty = sellerShare * 1;

        setCreateBill((data: any) => ({
          ...data,
          add_product: [
            ...data.add_product,
            {
              ...filteredSelectedData[0],
              quantitySelected: qtySelected,
              reorderedInventoryRef: reorderedInventoryRef,
              total_seller_price_with_qty:
                Number(filteredSelectedData[0]?.finalSellerPrice) * 1,
              storeMarginAmount: Number(
                filteredSelectedData[0]?.storeMarginAmount
              ),
              storeMarginAmountWithQty:
                (Number(filteredSelectedData[0]?.storeMarginAmount) +
                  Number(filteredSelectedData[0]?.storeMarginAmount) * 0.18) *
                1,
              product_total: Number(filteredSelectedData[0]?.customer_pay) * 1,
              storeTDSonIT: tdsStoreAmt?.toFixed(2),
              storeShare: storeShare?.toFixed(2),
              finalStoreShare: storeShareWithQty?.toFixed(2),
              sellerTDSonIT: tdsAmt?.toFixed(2),
              sellerShare: sellerShare?.toFixed(2),
              finalSellerShare: sellerShareWithQty?.toFixed(2),
            },
          ],
        }));
      }
    } else {
      setCreateBill((data: any) => ({
        ...data,
        add_product: [...data.add_product],
      }));
    }
  };

  const handleDeviceId = async (checked?: boolean) => {
    if (updateId) {
      try {
        const payload: any = {
          tid: trasactionId,
          isToggle: checked === true || checked === false ? checked : isChecked,
        };
        const url = API_END_POINTS.POS_UPDATE_API.replace(":id", updateId);
        const response = await putMethod(url, payload);
        if (response?.data) {
          // if(response?.data?.isToggle === true){
          setTrasactionModal(false);
          // }
          getTrasactionId();
        }
      } catch (err) {
        console.log("error");
      }
    } else {
      try {
        const payload: any = {
          store_id: details?.registeration_id,
          tid: trasactionId,
          isToggle: isChecked,
        };
        const url = API_END_POINTS.POS_DEVICEID_POST;
        const response = await postMethod(url, payload);
        if (response?.data) {
          setTrasactionModal(false);
          getTrasactionId();
        }
      } catch (err) {
        console.log("error");
      }
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (trasactionId == "" && event.target.checked) {
      setTrasactionModal(true);
    }
    setPaymentInitiated({
      UPI: "",
      "CC/DC": "",
    });
    setIsChecked(event.target.checked);
    if (updateId) {
      handleDeviceId(event.target.checked);
    }
  };
  const handlePosSubmit = async (e: any) => {
    e.preventDefault();
    const button = e.target as HTMLButtonElement;
    handleProceed(button?.innerText === "Pay with UPI" ? "UPI" : "CC/DC");
    const actionId = button?.innerText === "Pay with UPI" ? "133" : "1";
    const { customer_name, mobile_number } = createBill;
    const isCustomerNameEmpty = customer_name == "";
    const isMobileNumberEmpty = mobile_number_Validation(mobile_number);
    if (!trasactionId) {
      toastNotifications.warning("Please enter device Id to proceed");
    } else if (!isCustomerNameEmpty && isMobileNumberEmpty) {
      try {
        const payload = {
          amount:
            paymentMethod?.length > 1
              ? button?.innerText === "Pay with UPI"
                ? amounts?.UPI
                : amounts?.CC_DC
              : calculateoverallItemTax(createBill.add_product, isCafe),
          actionId: actionId,
          tid: trasactionId,
        };
        const url = API_END_POINTS.POS_PAYMENT_INITIATION;
        const response = await postMethod(url, payload);
        if (response?.data?.urn) {
          setTypeOfMode(button?.innerText);
          setPaymentInitiated((pre) => ({
            ...pre,
            UPI:
              button?.innerText === "Pay with UPI"
                ? response?.data?.urn
                : paymentInitiate?.UPI,
            "CC/DC":
              button?.innerText === "Pay with UPI"
                ? paymentInitiate?.["CC/DC"]
                : response?.data?.urn,
          }));
          const invoiceNumber = response?.data?.invoiceNumber;
          setPaymentSuccessResult({
            ...paymentSuccessResult,
            upiURNPOSMachine: "",
            ccdcURNPOSMachine: "",
            posInvoiceNos: [
              {
                "POS-UPI":
                  button?.innerText === "Pay with UPI" ? invoiceNumber : "",
              },
              {
                "POS-CC_DC":
                  button?.innerText === "Pay with UPI" ? "" : invoiceNumber,
              },
            ],
          });
          toastNotifications.success("Payment Initiated");
        } else {
          setPaymentInitiated({
            UPI: "",
            "CC/DC": "",
          });
          toastNotifications.error("Payment Initiation Failed");
        }
      } catch (err) {
        console.log("error");
      }
    }
  };
  const handleStatusSubmit = async (e: any) => {
    try {
      const payload = {
        urn:
          typeOfMode == "Pay with UPI"
            ? paymentInitiate?.UPI
            : typeOfMode == "Pay with CC/DC"
            ? paymentInitiate?.["CC/DC"]
            : "",
      };
      const url = API_END_POINTS.POS_PAYMENT_STATUS;
      const response = await postMethod(url, payload);
      if (response?.data?.status) {
        const list_of_paymentModes = paymentMethod.map((method: any) => {
          const key = method === "CC/DC" ? "CC_DC" : method;
          return {
            [key]: amounts[key],
          };
        });
        setCreateBill({
          ...createBill,
          list_of_paymentModes: list_of_paymentModes,
        });
        setShowPaymentModal(true);
        if (typeOfMode === "Pay with UPI") {
          setPaymentStatus((prevStatus: any) => ({
            ...prevStatus,
            UPI: response.data.status,
          }));
        } else if (typeOfMode === "Pay with CC/DC") {
          setPaymentStatus((prevStatus: any) => ({
            ...prevStatus,
            "CC/DC": response.data.status,
          }));
        }
        const urn = response?.data?.urn;
        setPaymentSuccessResult({
          ...paymentSuccessResult,
          upiURNPOSMachine: typeOfMode === "Pay with UPI" ? urn : "",
          ccdcURNPOSMachine: typeOfMode === "Pay with UPI" ? "" : urn,
        });
      }
    } catch (err) {
      console.log("error");
    }
  };

  const totalPaymentAmount = createBill?.list_of_paymentModes.reduce(
    (total: number, method: any) => {
      const amountStrOrNum = Object.values(method)[0];
      const amount =
        typeof amountStrOrNum === "number"
          ? amountStrOrNum
          : parseFloat(amountStrOrNum as string);
      return total + (isNaN(amount) ? 0 : amount);
    },
    0
  );
  const isCC_DC_Present = createBill?.list_of_paymentModes.some((method: any) =>
    Object.keys(method).includes("CC_DC")
  );

  const isUPI_Present = createBill?.list_of_paymentModes.some((method: any) =>
    Object.keys(method).includes("UPI")
  );

  const isCash_Present = createBill?.list_of_paymentModes.some((method: any) =>
    Object.keys(method).includes("Cash")
  );
  const isCC_DC_Status_Success = isCC_DC_Present
    ? paymentStatus["CC/DC"] === "success"
    : true;
  const isUPI_Status_Success = isUPI_Present
    ? paymentStatus["UPI"] === "success"
    : true;
  const isCash_Status_Success = isCash_Present ? amountPaid === true : true;
  const isButtonDisabled =
    totalPaymentAmount < totalAmountCalc ||
    !isCC_DC_Status_Success ||
    !isUPI_Status_Success ||
    !isCash_Status_Success;

  const getTrasactionId = async () => {
    try {
      const url = API_END_POINTS.POS_DEVICEID_GET.replace(
        ":store_id",
        details?.registeration_id
      );
      const response = await getMethod(url);
      if (response?.data) {
        setTrasactionId(response?.data?.tid);
        setIsChecked(response?.data?.isToggle);
        setUpdateId(response?.data?.id);
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      const errorMessage = errorResponse?.error?.error_description;
      toastNotifications.error(errorMessage);
    }
  };

  useEffect(() => {
    if (!updateId) {
      getTrasactionId();
    }
  }, [trasactionId]);

  const handleModalClose = () => {
    setTrasactionModal(false);
    getTrasactionId();
  };

  return (
    <div className="bill-container">
      <ToastContainer
        autoClose={1}
        hideProgressBar={true}
        closeButton={false}
      />
      <div className="product-enter-details-container mt-2">
        <div className="product-enter-details-sec">
          <div>
            <span className="product-enter-details-sec-header product-subdetails-sec">
              Customer Details:
            </span>
            {billViewType !== "bill_history" && (
              <div>
                <div className="d-flex flex-row">
                  <input
                    disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setIsGSTAvailable(!isGSTAvailable);
                      // if checkbox is false then clear the gst data
                      setCreateBill({
                        ...createBill,
                        isGstChecked: isChecked,
                        gst: isChecked ? createBill.gst : "",
                      });
                    }}
                    type={"checkbox"}
                    checked={createBill.isGstChecked}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      marginRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    GST Available ?
                  </span>
                </div>
                <div className="d-flex flex-row">
                  <input
                    disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                    type={"checkbox"}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setIsHomeDelivery(!isHomeDelivery);
                      setCreateBill({
                        ...createBill,
                        isHomeDeliveryChecked: isChecked,
                        address: isChecked ? createBill.address : "",
                      });
                    }}
                    checked={createBill.isHomeDeliveryChecked}
                  />
                  <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
                    Home Delivery ?
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="product-subdetails-sec">
            <div className="product-subdetails-item-sec">
              <span>
                Customer Name <span style={{ color: "red" }}>*</span>
              </span>
              <input
                type={"text"}
                disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                value={createBill.customer_name}
                onChange={(e) => {
                  const value = e.target.value;
                  const trimmedValue = value.replace(/[^a-zA-Z\s]/g, "");
                  setCustomerName(trimmedValue);
                  setCreateBill({ ...createBill, customer_name: trimmedValue });
                }}
              />
            </div>
            <div className="product-subdetails-item-sec">
              <span>
                Mobile No (+91)<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="tel"
                maxLength={10}
                disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                onChange={(e) => {
                  const value = e.target.value;
                  const sanitizedValue = value.replace(/\D/g, "");
                  const truncatedValue = sanitizedValue.slice(0, 10);
                  setCreateBill({
                    ...createBill,
                    mobile_number: truncatedValue,
                  });
                }}
                value={createBill.mobile_number}
              />
            </div>

            {/* when user select, isgstavailable checkbox then it will be appear */}
            {createBill.isGstChecked && (
              <div className="product-subdetails-item-sec">
                <span>GST</span>
                <input
                  type={"text"}
                  maxLength={15}
                  disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                  value={createBill.gst}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^a-zA-Z0-9]/g, "");
                    value = value.substring(0, 15);
                    setCreateBill({ ...createBill, gst: value });
                  }}
                />
              </div>
            )}
            {/* when user selected, home delivery checkbox then it will appear */}
            {createBill.isHomeDeliveryChecked && (
              <div className={`product-subdetails-item-sec`}>
                <span>Address</span>
                <textarea
                  disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                  value={createBill.address}
                  onChange={(e) => {
                    const value = e.target.value;
                    // setCustomerAddress(value)
                    setCreateBill({ ...createBill, address: value });
                  }}
                />
              </div>
            )}
            {billViewType !== "bill_history" && (
              <div className="product-subdetails-item-sec">
                <span>Device Id</span>
                <input
                  type={"text"}
                  value={trasactionId}
                  onChange={(e) => {
                    setTrasactionModal(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {billViewType !== "bill_history" && (
          <div className="d-flex justify-content-end align-items-start mt-3">
            <label
              className="form-check-label pe-3 text-dark fw-bold"
              htmlFor="flexSwitchCheckDefault"
            >
              Click on the toggle to proceed with POS machine
            </label>
            <div className="form-check form-switch ">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={isChecked}
                onChange={handleToggleChange}
                disabled={
                  createBill.add_product.length > 0 &&
                  paymentMethod?.length > 0 &&
                  !isButtonDisabled
                }
              />
            </div>
          </div>
        )}
      </div>

      {clcAddProductFncAvailabilty() && (
        <div
          className={`add-product-sec ${
            isStore ? "" : "d-flex justify-content-end"
          } `}
        >
          <div className="d-flex justify-content-center flexDirectionAlign w-100">
            {isStore && (
              <div className="itemcode-barcode">
                <input
                  placeholder="Enter Product Code/Scan Product"
                  className="itemcode-barcode-input"
                  onChange={(e) => {
                    handleBarcodeOrValueRead(e.target.value);
                    setItemCode(e.target.value);
                  }}
                  value={itemCode}
                />
              </div>
            )}
            {/* add product button */}
            {isStore && (
              <div className="stock-dropdown mt-1">
                <Select
                  className="add-batch-sec"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  isSearchable={true}
                  placeholder="Select Stock"
                  isClearable={true}
                />
              </div>
            )}
          </div>
          <div
            onClick={onAddProductButtonHandler}
            className="add-new-product-sec"
          >
            <img src={addIcon} alt="add_icon" title="Add Product" />
            <span>Add Product</span>
          </div>
        </div>
      )}

      {/* display products data */}
      <div className="added-products-details-sec mt-3">
        <ProductTable
          productDeletedToast={toastNotifications.warning}
          clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
          totalCalculation={totalCalculation}
          billViewType={billViewType}
          setToBePaid={setToBePaid}
          setDeletedData={setDeletedData}
          billData={
            billViewType == BILL_VIEW_TYPES.BILL_HISTORY ? createBill : ""
          }
          setCreateBill={setCreateBill}
          createBill={createBill}
          onClearBillHandler={onClearBillHandler}
          nationalAdminGstRate={gstRate}
          setPaymentInitiated={setPaymentInitiated}
          deleteBillDetails={deleteBillDetails}
          queryType={myParam}
        />
      </div>

      {billViewType == "create_bill" &&
      createBill.add_product.length > 0 &&
      paymentMethod?.length > 0 ? (
        <>
          {amountPaid == false ? (
            <>
              <div className="cash-input-div">
                <div>
                  {/* single selection */}
                  {paymentMethod.length == 1 &&
                    paymentMethod.includes("UPI") && (
                      <>
                        <div className="d-flex cash-input-div mb-2">
                          <div className="mt-1">
                            Payable amount :{" "}
                            {calculateoverallItemTax(
                              createBill.add_product,
                              isCafe
                            )}
                          </div>
                          <PayWithUpi
                            generatedToken={generatedUpiToken?.hash}
                            txnId={generatedUpiToken?.txnId}
                            handleProceed={handleProceed}
                            disabledValue={
                              upiPaymentSuccess || paymentInitiate?.UPI
                            }
                            upiPaymentSuccess={upiPaymentSuccess}
                            isCustomerNameEmpty={
                              createBill?.customer_name == ""
                            }
                            isMobileNumberEmpty={mobile_number_Validation(
                              createBill?.mobile_number
                            )}
                            mobileNumber={createBill?.mobile_number}
                            setUpiMsgString={setUpiMsgString}
                            setUpiPaymentSuccess={setUpiPaymentSuccess}
                            isChecked={isChecked}
                            handlePosSubmit={handlePosSubmit}
                            consumerId={userInfoDetails.sub}
                            merchatId={merchatId}
                            amtToBePaid={calculateoverallItemTax(
                              createBill.add_product,
                              isCafe
                            )}
                          />
                        </div>
                      </>
                    )}
                  {paymentMethod.length == 1 &&
                    paymentMethod.includes("CC/DC") && (
                      <>
                        <div className="d-flex cash-input-div mb-2">
                          <div className="mt-1">
                            Payable amount :{" "}
                            {calculateoverallItemTax(
                              createBill.add_product,
                              isCafe
                            )}
                          </div>

                          <PayWithCcDc
                            generatedToken={generatedCcdcToken?.hash}
                            txnId={generatedCcdcToken?.txnId}
                            handleProceed={handleProceed}
                            disabledValue={
                              ccPaymentSuccess || paymentInitiate?.["CC/DC"]
                            }
                            ccPaymentSuccess={ccPaymentSuccess}
                            isCustomerNameEmpty={
                              createBill?.customer_name == ""
                            }
                            isMobileNumberEmpty={mobile_number_Validation(
                              createBill?.mobile_number
                            )}
                            mobileNumber={createBill?.mobile_number}
                            setCcdcMsgString={setCcdcMsgString}
                            setCcPaymentSuccess={setCcPaymentSuccess}
                            isChecked={isChecked}
                            handlePosSubmit={handlePosSubmit}
                            consumerId={userInfoDetails.sub}
                            merchatId={merchatId}
                            amtToBePaid={calculateoverallItemTax(
                              createBill.add_product,
                              isCafe
                            )}
                          />
                        </div>
                      </>
                    )}
                  {paymentMethod.length == 1 &&
                    paymentMethod.includes("Cash") && (
                      <div className="cash-func mb-2">
                        {calcTrue ? (
                          <div className="cash-input-div">
                            <div className="product-subdetails-item-sec">
                              <label>Cash</label>
                              <input
                                name="cashin"
                                value={cashIn}
                                type="number"
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (validInput(inputValue) == true) {
                                    setCashIn(inputValue);
                                  }
                                }}
                                placeholder="Enter the amount"
                              />
                              {/* display error */}
                              {cashIn && cashIn < totalAmountCalc ? (
                                <span
                                  className="text-danger text-capitalize mt-1"
                                  style={{ fontSize: "12px" }}
                                >
                                  amount should be greater than{" "}
                                  {totalAmountCalc}
                                </span>
                              ) : null}
                            </div>
                            <div className="my-3">
                              <Button
                                className="calc-btn text-center text-capitalize d-flex justify-content-center align-items-center"
                                disabled={cashIn < totalAmountCalc}
                                onClick={handleCashCalc}
                                title="Calculate"
                              >
                                Calculate
                              </Button>
                            </div>
                          </div>
                        ) : cashIn ? (
                          <div className="calc-data">
                            <div className="calc-data-item">
                              Cash-in :{" "}
                              <span className="calc-data-item-value">
                                {cashIn}
                              </span>{" "}
                            </div>
                            <div className="calc-data-item">
                              Paid :{" "}
                              <span className="calc-data-item-value">
                                {totalAmountCalc}
                              </span>{" "}
                            </div>
                            <div className="calc-data-item">
                              Cash-out :{" "}
                              <span className="calc-data-item-value">
                                {totalAmountCalc}
                              </span>{" "}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  {/* double selection */}
                  {paymentMethod.length == 2 &&
                    paymentMethod.includes("Cash") && (
                      <>
                        <div className="d-flex cash-input-div">
                          <span className="px-3">Split Amount :</span>
                          {/* {paymentMethod.map((method: any) => ( */}
                          <div
                            key={"Cash"}
                            className="product-subdetails-item-sec"
                          >
                            <label>{"Cash"} </label>
                            <input
                              className="payment-inputs my-2"
                              name={"Cash"}
                              type="number"
                              value={amounts.Cash}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setAmounts({
                                    ...amounts,
                                    Cash: inputValue,
                                  });
                                }
                              }}
                              placeholder={`Enter the amount`}
                            />
                          </div>
                          {paymentMethod.includes("UPI") && (
                            <div
                              key={"UPI"}
                              className="product-subdetails-item-sec"
                            >
                              <label>{"UPI"} </label>
                              <input
                                className="payment-inputs my-2"
                                name={"UPI"}
                                type="number"
                                value={amounts.UPI}
                                onChange={(e) => {
                                  setPaymentInitiated((pre) => ({
                                    ...pre,
                                    UPI: "",
                                  }));
                                  const inputValue = e.target.value;
                                  if (validInput(inputValue) == true) {
                                    setAmounts({
                                      ...amounts,
                                      UPI: inputValue,
                                    });
                                  }
                                  generateHashToken("upi", inputValue);
                                }}
                                placeholder={`Enter the amount`}
                              />
                            </div>
                          )}
                          {paymentMethod.includes("CC/DC") && (
                            <div
                              key={"CC/DC"}
                              className="product-subdetails-item-sec"
                            >
                              <label>{"CC/DC"} </label>
                              <input
                                className="payment-inputs my-2"
                                name={"CC/DC"}
                                type="number"
                                value={amounts.CC_DC}
                                onChange={(e) => {
                                  setPaymentInitiated((pre) => ({
                                    ...pre,
                                    "CC/DC": "",
                                  }));
                                  const inputValue = e.target.value;
                                  if (validInput(inputValue) == true) {
                                    setAmounts({
                                      ...amounts,
                                      CC_DC: inputValue,
                                    });
                                  }
                                  generateHashToken("ccdc", inputValue);
                                }}
                                placeholder={`Enter the amount`}
                              />
                            </div>
                          )}

                          {paymentMethod.includes("UPI") && (
                            <PayWithUpi
                              generatedToken={generatedUpiToken?.hash}
                              txnId={generatedUpiToken?.txnId}
                              handleProceed={handleProceed}
                              disabledValue={
                                !(
                                  (amounts.UPI && amounts.Cash) ||
                                  (amounts.CC_DC && amounts.Cash)
                                ) ||
                                upiPaymentSuccess ||
                                ccPaymentSuccess ||
                                doublePaymentError ||
                                paymentInitiate?.UPI
                              }
                              upiPaymentSuccess={upiPaymentSuccess}
                              isCustomerNameEmpty={
                                createBill?.customer_name == ""
                              }
                              isMobileNumberEmpty={mobile_number_Validation(
                                createBill?.mobile_number
                              )}
                              mobileNumber={createBill?.mobile_number}
                              setUpiMsgString={setUpiMsgString}
                              setUpiPaymentSuccess={setUpiPaymentSuccess}
                              isChecked={isChecked}
                              handlePosSubmit={handlePosSubmit}
                              consumerId={userInfoDetails.sub}
                              merchatId={merchatId}
                              amtToBePaid={amounts.UPI}
                            />
                          )}
                          {paymentMethod.includes("CC/DC") && (
                            <PayWithCcDc
                              generatedToken={generatedCcdcToken?.hash}
                              txnId={generatedCcdcToken?.txnId}
                              handleProceed={handleProceed}
                              disabledValue={
                                !(
                                  (amounts.UPI && amounts.Cash) ||
                                  (amounts.CC_DC && amounts.Cash)
                                ) ||
                                upiPaymentSuccess ||
                                ccPaymentSuccess ||
                                doublePaymentError ||
                                paymentInitiate?.["CC/DC"]
                              }
                              ccPaymentSuccess={ccPaymentSuccess}
                              isCustomerNameEmpty={
                                createBill?.customer_name == ""
                              }
                              isMobileNumberEmpty={mobile_number_Validation(
                                createBill?.mobile_number
                              )}
                              mobileNumber={createBill?.mobile_number}
                              setCcdcMsgString={setCcdcMsgString}
                              setCcPaymentSuccess={setCcPaymentSuccess}
                              isChecked={isChecked}
                              handlePosSubmit={handlePosSubmit}
                              consumerId={userInfoDetails.sub}
                              merchatId={merchatId}
                              amtToBePaid={amounts.CC_DC}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-center mb-2">
                          {doublePaymentError ? (
                            <span className="text-danger text-capitalize mt-1 amount-error">
                              amount should be greater than {totalAmountCalc}
                            </span>
                          ) : null}
                        </div>
                      </>
                    )}
                  {paymentMethod.length == 2 &&
                    paymentMethod.includes("UPI") &&
                    paymentMethod.includes("CC/DC") && (
                      <>
                        <div className="d-flex cash-input-div">
                          <span className="px-3">Split Amount :</span>
                          <div
                            key={"UPI"}
                            className="product-subdetails-item-sec"
                          >
                            <label>{"UPI"} </label>
                            <input
                              className="payment-inputs my-2"
                              name={"UPI"}
                              type="number"
                              value={amounts.UPI}
                              onChange={(e) => {
                                setPaymentInitiated((pre) => ({
                                  ...pre,
                                  UPI: "",
                                }));
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setAmounts({
                                    ...amounts,
                                    UPI: inputValue,
                                  });
                                }
                                generateHashToken("upi", inputValue);
                              }}
                              placeholder={`Enter the amount`}
                            />
                          </div>
                          <div
                            key={"CC/DC"}
                            className="product-subdetails-item-sec"
                          >
                            <label>{"CC/DC"} </label>
                            <input
                              className="payment-inputs my-2"
                              name={"CC/DC"}
                              type="number"
                              value={amounts.CC_DC}
                              onChange={(e) => {
                                setPaymentInitiated((pre) => ({
                                  ...pre,
                                  "CC/DC": "",
                                }));
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setAmounts({
                                    ...amounts,
                                    CC_DC: inputValue,
                                  });
                                }
                                generateHashToken("ccdc", inputValue);
                              }}
                              placeholder={`Enter the amount`}
                            />
                          </div>

                          <PayWithUpi
                            generatedToken={generatedUpiToken?.hash}
                            txnId={generatedUpiToken?.txnId}
                            handleProceed={handleProceed}
                            disabledValue={
                              !amounts.UPI ||
                              upiPaymentSuccess ||
                              doublePaymentError ||
                              paymentInitiate?.UPI
                            }
                            upiPaymentSuccess={upiPaymentSuccess}
                            isCustomerNameEmpty={
                              createBill?.customer_name == ""
                            }
                            isMobileNumberEmpty={mobile_number_Validation(
                              createBill?.mobile_number
                            )}
                            mobileNumber={createBill?.mobile_number}
                            setUpiMsgString={setUpiMsgString}
                            setUpiPaymentSuccess={setUpiPaymentSuccess}
                            isChecked={isChecked}
                            handlePosSubmit={handlePosSubmit}
                            consumerId={userInfoDetails.sub}
                            merchatId={merchatId}
                            amtToBePaid={amounts.UPI}
                          />
                          <PayWithCcDc
                            generatedToken={generatedCcdcToken?.hash}
                            txnId={generatedCcdcToken?.txnId}
                            handleProceed={handleProceed}
                            disabledValue={
                              !amounts.CC_DC ||
                              ccPaymentSuccess ||
                              doublePaymentError ||
                              paymentInitiate?.["CC/DC"]
                            }
                            ccPaymentSuccess={ccPaymentSuccess}
                            isCustomerNameEmpty={
                              createBill?.customer_name == ""
                            }
                            isMobileNumberEmpty={mobile_number_Validation(
                              createBill?.mobile_number
                            )}
                            mobileNumber={createBill?.mobile_number}
                            setCcdcMsgString={setCcdcMsgString}
                            setCcPaymentSuccess={setCcPaymentSuccess}
                            isChecked={isChecked}
                            handlePosSubmit={handlePosSubmit}
                            consumerId={userInfoDetails.sub}
                            merchatId={merchatId}
                            amtToBePaid={amounts.CC_DC}
                          />
                        </div>
                        <div className="d-flex justify-content-center mb-2">
                          {doublePaymentError ? (
                            <span className="text-danger text-capitalize mt-1 amount-error">
                              amount should be greater than {totalAmountCalc}
                            </span>
                          ) : null}
                        </div>
                      </>
                    )}
                  {/* Triple selection */}
                  {paymentMethod.length === 3 && (
                    <>
                      <div className="d-flex cash-input-div">
                        <span className="px-3">Split Amount :</span>

                        <div
                          key={"Cash"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"Cash"} </label>
                          <input
                            className="payment-inputs my-2"
                            name={"Cash"}
                            type="number"
                            value={amounts.Cash}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  Cash: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>
                        <div
                          key={"UPI"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"UPI"} </label>
                          <input
                            className="payment-inputs my-2"
                            name={"UPI"}
                            value={amounts.UPI}
                            type="number"
                            onChange={(e) => {
                              setPaymentInitiated((pre) => ({
                                ...pre,
                                UPI: "",
                              }));
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  UPI: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>
                        <div
                          key={"CC/DC"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"CC/DC"} </label>
                          <input
                            className="payment-inputs my-2"
                            name={"CC/DC"}
                            type="number"
                            value={amounts.CC_DC}
                            onChange={(e) => {
                              setPaymentInitiated((pre) => ({
                                ...pre,
                                "CC/DC": "",
                              }));
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  CC_DC: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>

                        <PayWithUpi
                          generatedToken={generatedUpiToken?.hash}
                          txnId={generatedUpiToken?.txnId}
                          handleProceed={handleProceed}
                          disabledValue={
                            !amounts.UPI ||
                            upiPaymentSuccess ||
                            multiplePaymentError ||
                            paymentInitiate?.UPI
                          }
                          upiPaymentSuccess={upiPaymentSuccess}
                          isCustomerNameEmpty={createBill?.customer_name == ""}
                          isMobileNumberEmpty={mobile_number_Validation(
                            createBill?.mobile_number
                          )}
                          mobileNumber={createBill?.mobile_number}
                          setUpiMsgString={setUpiMsgString}
                          setUpiPaymentSuccess={setUpiPaymentSuccess}
                          isChecked={isChecked}
                          handlePosSubmit={handlePosSubmit}
                          consumerId={userInfoDetails.sub}
                          merchatId={merchatId}
                          amtToBePaid={amounts.UPI}
                        />
                        <PayWithCcDc
                          generatedToken={generatedCcdcToken?.hash}
                          txnId={generatedCcdcToken?.txnId}
                          handleProceed={handleProceed}
                          disabledValue={
                            !amounts.CC_DC ||
                            ccPaymentSuccess ||
                            multiplePaymentError ||
                            paymentInitiate?.["CC/DC"]
                          }
                          ccPaymentSuccess={ccPaymentSuccess}
                          isCustomerNameEmpty={createBill?.customer_name == ""}
                          isMobileNumberEmpty={mobile_number_Validation(
                            createBill?.mobile_number
                          )}
                          mobileNumber={createBill?.mobile_number}
                          setCcdcMsgString={setCcdcMsgString}
                          setCcPaymentSuccess={setCcPaymentSuccess}
                          isChecked={isChecked}
                          handlePosSubmit={handlePosSubmit}
                          consumerId={userInfoDetails.sub}
                          merchatId={merchatId}
                          amtToBePaid={amounts.CC_DC}
                        />
                      </div>
                      <div className="d-flex justify-content-center mb-2">
                        {multiplePaymentError ? (
                          <span className="text-danger text-capitalize mt-1 amount-error">
                            amount should be greater than {totalAmountCalc}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                {(paymentMethod.length === 1
                  ? upiPaymentSuccess || ccPaymentSuccess
                  : paymentMethod.length === 2
                  ? (amounts.Cash &&
                      (upiPaymentSuccess ||
                        paymentStatus?.UPI === "success" ||
                        paymentStatus?.["CC/DC"] === "success")) ||
                    (amounts.Cash && ccPaymentSuccess) ||
                    (upiPaymentSuccess && ccPaymentSuccess)
                  : paymentMethod.length === 3
                  ? (amounts.Cash && upiPaymentSuccess && ccPaymentSuccess) ||
                    (amounts.Cash &&
                      paymentStatus?.UPI === "success" &&
                      paymentStatus?.["CC/DC"] === "success")
                  : false) && (
                  <button
                    className="mx-2 btn btn-primary btn-sm "
                    onClick={handlePay}
                    title="Submit"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-end">
                {paymentListLength > 0 && (
                  <div className="calc-data">
                    <div className="calc-data-item">
                      {createBill.list_of_paymentModes.map((payment: any) => {
                        const paymentKey = Object.keys(payment)[0];
                        const paymentValue = payment[paymentKey];
                        return `${paymentKey}:  ${paymentValue + " "}`;
                      })}
                    </div>
                    <div className="calc-data-item">
                      Paid :{" "}
                      <span className="calc-data-item-value">
                        {totalPayment}
                      </span>{" "}
                    </div>
                    <div className="calc-data-item">
                      Cash-out :{" "}
                      <span className="calc-data-item-value">
                        {totalPayment &&
                          (parseFloat(totalPayment) - totalAmountCalc).toFixed(
                            2
                          )}
                      </span>{" "}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : null}

      {createBill.add_product.length > 0 && (
        <div
          className={
            billViewType == "refund_bill" ? "payment-draft-container" : "mb-2"
          }
        >
          {billViewType == "refund_bill" && (
            <input
              className="refund-comment-input"
              placeholder="Add Comment"
              value={refundComment}
              onChange={(e) => setRefundComment(e.target.value)}
              name="refund"
            />
          )}
          {(paymentInitiate?.UPI || paymentInitiate?.["CC/DC"]) && (
            <div className="d-flex justify-content-end align-items-center mt-3 mb-3">
              <label className="form-check-label pe-3 text-danger">
                Click on Payment status to complete the bill
              </label>
              <div>
                <button
                  className="mx-2 btn btn-primary btn-sm"
                  onClick={(e: any) => handleStatusSubmit(e)}
                  title="Payment Status"
                >
                  Payment Status
                </button>
              </div>
            </div>
          )}
          {/* payment options */}
          <div className="payment-draft-sec">
            <div className="note-sec my-2">
              {createBill.add_product.length > 0 && (
                <>
                  <div className="product-subdetails-item-sec">
                    <span>Public Note</span>
                    <textarea
                      disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                      value={createBill.public_note}
                      onChange={(e) =>
                        setCreateBill({
                          ...createBill,
                          public_note: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="product-subdetails-item-sec">
                    <span>Internal Note</span>
                    <textarea
                      disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                      value={createBill.internal_note}
                      onChange={(e) =>
                        setCreateBill({
                          ...createBill,
                          internal_note: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center my-2">
              {Object.keys(PAYMENTMETHOD).map((option: string) => (
                <>
                  <input
                    key={PAYMENTMETHOD[option]}
                    disabled={
                      billViewType == "refund_bill" ||
                      billViewType == "bill_history" ||
                      billData.status == "Done" ||
                      amountPaid ||
                      (!isButtonDisabled && true)
                    }
                    hidden={billViewType == "bill_history"}
                    value={checkboxValues[PAYMENTMETHOD[option]].toString()}
                    onChange={(e) => {
                      setAmounts((pre: any) => ({
                        ...pre,
                        Cash: "",
                        UPI: "",
                        CC_DC: "",
                      }));
                      const value = e.target.checked;
                      handlePaymentOnchange(PAYMENTMETHOD[option]);
                      setCheckboxValues((prevValues) => ({
                        ...prevValues,
                        [PAYMENTMETHOD[option]]: value,
                      }));
                      setCalcTrue(
                        PAYMENTMETHOD.CASH === PAYMENTMETHOD[option] ||
                          checkboxValues[PAYMENTMETHOD[option]] == true
                      );
                    }}
                    type={"checkbox"}
                    id={PAYMENTMETHOD[option]}
                  />
                  <label
                    htmlFor={PAYMENTMETHOD[option]}
                    className="checkbox-label"
                    hidden={billViewType == "bill_history"}
                  >
                    {PAYMENTMETHOD[option]}
                  </label>
                </>
              ))}
              {billViewType == BILL_VIEW_TYPES.BILL_HISTORY && (
                <>
                  <div className="mx-4">
                    Payment Methods:{" "}
                    <span className="gap-2">
                      {billData.list_of_paymentModes &&
                      billData.list_of_paymentModes.length !== 0
                        ? billData.list_of_paymentModes.map((payment: any) => {
                            const paymentKey = Object.keys(payment)[0];
                            const paymentValue = payment[paymentKey];
                            return `${paymentKey}: ${paymentValue + " "}`;
                          })
                        : ""}
                    </span>
                  </div>
                </>
              )}
            </div>
            {/* 
            {/* clear button */}
            <div className="d-flex justify-content-center align-items-center my-2">
              {calculateClearButtonVisibility() &&
                billViewType === BILL_VIEW_TYPES.CREATE_BILL && (
                  <Button
                    onClick={() => {
                      if (myParam == "resume") {
                        deleteBillDetails(createBill?.add_product[0]?.order_id);
                      }
                      onClearBillHandler();
                    }}
                    className="text-capitalize"
                    disabled={
                      upiPaymentSuccess ||
                      ccPaymentSuccess ||
                      cashIn ||
                      !isButtonDisabled
                    }
                    title="Clear Bill"
                  >
                    Clear Bill
                  </Button>
                )}

              {calculateSaveButtonVisibility() ? (
                <Button
                  onClick={onHoldHandler}
                  className="text-capitalize"
                  disabled={
                    upiPaymentSuccess ||
                    ccPaymentSuccess ||
                    cashIn ||
                    !isButtonDisabled
                  }
                  title="Hold"
                >
                  hold
                </Button>
              ) : (
                ""
              )}

              {/* confirm button */}
              {billViewType == BILL_VIEW_TYPES.CREATE_BILL && (
                <Button
                  onClick={onConfirmHandler}
                  disabled={
                    isChecked
                      ? isButtonDisabled
                      : confirmDisabledCondition ||
                        doublePaymentError ||
                        multiplePaymentError
                  }
                  className="text-capitalize "
                  title="Confirm"
                >
                  confirm
                </Button>
              )}

              {/* pdf print */}
              {billViewType == BILL_VIEW_TYPES.BILL_HISTORY &&
                billData?.payment_method != "" &&
                billData.payment_status == PAYMENT_STATUS.PAID && (
                  <PDFGenerate billData={billData} hsnCode={hsnCode} />
                )}

              {/* in bill history modal popup, if payment is empty then display this resume buttonI */}
              {billViewType == BILL_VIEW_TYPES.BILL_HISTORY &&
                (billData?.payment_method == "" ||
                  billData.payment_status == PAYMENT_STATUS.PENDING) && (
                  <button
                    onClick={() => {
                      navigate.push(ROUTES_PATH.CREATE_BILL + "?type=resume", {
                        billData,
                      });
                    }}
                    title="Resume"
                  >
                    Resume
                  </button>
                )}
            </div>
          </div>
        </div>
      )}

      {isAddProductModalOpen && (
        <AddProductModal
          productModalHeader={productModalHeader}
          isProductSearchable={isProductSearchable}
          setIsAddProductModalOpen={setIsAddProductModalOpen}
          productAddedSuccessToast={toastNotifications.success}
          setAddedProductList={setAddedProductList}
          isInventoryListLoading={isInventoryListLoading}
          inventoryListData={inventoryListData}
          productSelectedFromItemCode={productSelectedFromItemCode}
          setProductSelectedFromItemCode={setProductSelectedFromItemCode}
          setCreateBill={setCreateBill}
          setInventoryId={setInventoryId}
          addedproductList={addedproductList}
          tdsRate={tdsRate}
          tcsCgstRate={tcsCgstRate}
          tcsSgstRate={tcsSgstRate}
          isCafeIsStoreBoolean={{
            tdsStore: isCafeIsStoreTdsStore,
            tdsCafe: isCafeIsStoreTdsCafe,
            tcsCgstStore: isCafeIsStoreTcsCgstStore,
            tcsCgstCafe: isCafeIsStoreTcsCgstCafe,
            tcsSgstStore: isCafeIsStoreTcsSgstStore,
            tcsSgstCafe: isCafeIsStoreTcsSgstCafe,
          }}
        />
      )}

      {upiFlag && qrImage ? (
        <Modal header={"QR CODE"} width={"30%"} setOpenModal={setUpiFlag}>
          <img
            src={qrImage}
            title="QR Code"
            alt="QR CODE"
            style={{ maxHeight: "20%" }}
          />
          <button
            className="calc-btn text-center text-capitalize d-flex justify-content-center align-items-center"
            onClick={() => setUpiFlag(false)}
            title="Pay"
          >
            Pay
          </button>
        </Modal>
      ) : null}

      {trasactionModal && (
        <Modal header={"Device Id"} width={"50%"} apiCall={handleModalClose}>
          <div className="mt-5 d-flex justify-content flex-column align-items-center">
            <div>
              <input
                className="form-control border-dark rounded"
                placeholder="Enter Device Id"
                value={trasactionId}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[a-zA-Z0-9]*$/.test(value)) {
                    setTrasactionId(value);
                  }
                }}
                name="trasactionId"
              />
            </div>
            <button
              className="failed-btn text-center text-capitalize"
              onClick={() => handleDeviceId()}
              title="Ok"
            >
              Ok
            </button>
          </div>
        </Modal>
      )}

      {showPaymentModal && (
        <Modal
          header={"Payment Status"}
          width={"30%"}
          setOpenModal={setShowPaymentModal}
        >
          <div className="mt-5 d-flex justify-content flex-column align-items-center">
            <div>
              <label
                className={
                  typeOfMode === "Pay with UPI"
                    ? paymentStatus?.UPI === "success"
                      ? "form-check-label pe-3 text-success pw-b"
                      : "form-check-label pe-3 text-danger"
                    : paymentStatus?.["CC/DC"] === "success"
                    ? "form-check-label pe-3 text-success pw-b"
                    : "form-check-label pe-3 text-danger"
                }
              >
                {typeOfMode === "Pay with UPI"
                  ? paymentStatus?.UPI === "success"
                    ? "Payment transaction of UPI is successful"
                    : paymentStatus?.UPI === "INITIATE"
                    ? "Payment transaction of UPI is initiated... Please complete the payment"
                    : "Payment transaction of UPI is failed. Please try again"
                  : paymentStatus?.["CC/DC"] === "success"
                  ? "Payment transaction of CC/DC is successful"
                  : paymentStatus?.["CC/DC"] === "INITIATE"
                  ? "Payment transaction of CC/DC is initiated... Please complete the payment"
                  : "Payment transaction of CC/DC is failed. Please try again"}
              </label>
            </div>
            <button
              className={
                typeOfMode === "Pay with UPI"
                  ? paymentStatus?.UPI === "success"
                    ? "success-btn text-center text-capitalize"
                    : "failed-btn text-center text-capitalize"
                  : paymentStatus?.["CC/DC"] === "success"
                  ? "success-btn text-center text-capitalize"
                  : "failed-btn text-center text-capitalize"
              }
              onClick={() => setShowPaymentModal(false)}
              title="Ok"
            >
              Ok
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}
