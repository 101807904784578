import React, { useState, useMemo, useEffect, Fragment } from "react";
import CircularProgressBar from "../../../components/Basic/CircularProgressBar";
import CircularProgressDone from "../../../components/Basic/CircularProgressDone";
import Select from "react-select";
import moment from "moment";
import "./styles.scss";
import { STORAGE_KEYS } from "../../../helpers/common";
import useToggleHook from "../../../hooks/useToggleHook";
import ModalPopup from "../../../components/Basic/Modalpopup/modalpopup";
import { Container, FormControl, Button, TabContainer } from "react-bootstrap";
import { getLocalStorage } from "../../../helpers/common";
import { postMethod, getMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { toast, ToastContainer } from "react-toastify";
import GenerateBarCodeImage from "./GenerateBarCodeImage";
import "../Barcode/styles.scss";
import TableContainer from "../../../components/Basic/TableContainer/TableContainer";

export default function () {
  interface Product {
    product_id: string;
    product_name: string;
    _id: string;
    brand: string;
    seller_details: any;
    expiry_date: string;
    current_qty: number;
  }

  interface SelectedOption {
    value: string;
    label: string;
    inventoryRef: string;
  }
  const { hideToggle, toggle } = useToggleHook();
  const [disabled, setDisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const [selectedTableData, setSelectedTableData] = useState({
    itemName: "",
    brandName: "",
    sellerName: "",
    expiryDate: "",
    qtyAvailable: "",
  });
  const [inputText, setInputText] = useState<string>("");
  const [generatedBarCode, setGeneratedBarCode] = useState("");
  const [isBardCodeGenerated, setIsBardCodeGenerated] = useState(false);
  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);
  const [inventoryProducts, setInventoryProducts] = useState<Product[]>([]);
  useEffect(() => {
    if (inputText == "" || selectedOption == null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [inputText, selectedOption]);

  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "-";
  };
  const columns: any = [
    {
      Header: "Item Name",
      accessor: "itemName",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand Name",
      accessor: "brandName",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Seller Name",
      accessor: "sellerName",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Expiry Date",
      accessor: "expiryDate",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Quantity Available",
      accessor: "qtyAvailable",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
  ];
  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      let response;
      let qty_filter_data;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        response = await postMethod(
          API_END_POINTS.INVENTORY_UTILS_SEARCH,
          data
        );
        qty_filter_data =
          response.data &&
          response.data?.filter(
            (qty: any) => !(qty.current_qty <= 0) && !qty.is_deleted
          );
      } else {
        response = await getMethod(
          API_END_POINTS.GET_HOTCOOK_PRODUCTS_QTY.replace(
            ":state",
            details?.location?.state
          ).replace(":store_id", details.registeration_id)
        );
        qty_filter_data =
          response.data &&
          response.data?.filter(
            (qty: any) =>
              !(
                qty?.quantity_details.length == 0 ||
                (qty?.quantity_details && qty?.quantity_details[0]?.qty <= 0)
              )
          );
      }
      const filteredQtyFilter = qty_filter_data?.filter((ele: any) => {
        return (
          !ele?.isStoreDeleted &&
          !ele?.isSellerDeleted &&
          !ele?.isCategoryDeleted
        );
      });
      setInventoryProducts(filteredQtyFilter);
    } catch (err) {}
  };
  useEffect(() => {
    getProductsFromInventory();
  }, []);

  const options = inventoryProducts.map((product: Product) => ({
    value: product.product_id,
    label:
      product.product_name +
      "-" +
      product.brand +
      "-" +
      product.seller_details?.name_of_organization +
      "-" +
      moment(product.expiry_date).format("DD/MM/YYYY") +
      "-" +
      product.current_qty,
    inventoryRef: product._id,
  }));

  const handleSelectChange = (selected: any) => {
    setIsBardCodeGenerated(false);
    setSelectedOption(selected);

    if (selected != null) {
      const filteredSelectedDate: any = inventoryProducts.filter((ele) => {
        return ele._id == selected.inventoryRef;
      });

      setSelectedTableData((data) => ({
        ...data,
        itemName: filteredSelectedDate[0]?.product_name,
        brandName: filteredSelectedDate[0]?.brand,
        sellerName:
          filteredSelectedDate[0]?.seller_details?.name_of_organization,
        expiryDate: moment(filteredSelectedDate[0]?.expiry_date).format(
          "DD/MM/YYYY"
        ),
        qtyAvailable: filteredSelectedDate[0]?.current_qty,
      }));
    } else {
      setSelectedTableData((data) => ({
        ...data,
        itemName: "",
        brandName: "",
        sellerName: "",
        expiryDate: "",
        qtyAvailable: "",
      }));
    }
  };
  const handleSubmit = async () => {
    try {
      if (!selectedOption || !inputText) {
        return;
      }
      if (Number(inputText) > Number(selectedTableData.qtyAvailable)) {
        toast.error(
          "Selected quantity should be less than available quantity!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        const data = {
          inventoryRef: selectedOption.inventoryRef,
          qty: Number(inputText),
        };
        const response = await postMethod(
          API_END_POINTS.ADD_LOOSE_PRODUCTS,
          data
        );

        if (response?.data?.barCode) {
          setIsBardCodeGenerated(true);
          setGeneratedBarCode(response?.data?.barCode);
          setSelectedOption(null);
          setInputText("");
        }
      }
    } catch (error) {}
  };

  const handleClick = () => {};

  return (
    <>
      <ToastContainer
        autoClose={1}
        hideProgressBar={true}
        closeButton={false}
      />
      <div className="container-fluid">
        <div>
          <div className="d-flex barCodeAlign mb-2">
            <Select
              className="custom-dropdown mx-2"
              value={selectedOption}
              onChange={handleSelectChange}
              options={options}
              isSearchable={true}
              placeholder="Search Product, Brand, Seller, Expiry Date, Quantity"
              isClearable={true}
            />
            <FormControl
              style={{ width: "200px" }}
              className="custom-text-field mx-2"
              type="number"
              placeholder="Quantity(Kg)"
              min={0}
              max={99}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />

            <Button
              className="custom-btn mx-2"
              variant="primary"
              onClick={handleSubmit}
              disabled={disabled}
              title="Submit"
            >
              Submit
            </Button>
          </div>
          {isBardCodeGenerated && (
            <GenerateBarCodeImage generatedBarCode={generatedBarCode} />
          )}

          <TableContainer
            columns={columns}
            data={[selectedTableData]}
            isGlobalFilter={false}
            handleClick={handleClick}
            PlaceholderValue={""}
            pageNav={false}
          />
        </div>
      </div>
      <ModalPopup
        isOpen={toggle}
        modalTitle={`Database Sync : ${toggle ? `Done` : "Please Wait"}`}
        onClose={hideToggle}
      >
        <Fragment>
          <Container className="d-flex justify-content-center align-items-center">
            {!toggle && <CircularProgressBar />}
            {toggle && <CircularProgressDone />}
          </Container>
        </Fragment>
      </ModalPopup>
    </>
  );
}
