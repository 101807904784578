import React, { Fragment, useEffect, useMemo, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { navItems, cafeItems } from "./navItems";
import { Online, Offline } from "react-detect-offline";
import "./styles.scss";
import {
  userIcon,
  sathibazarLogo,
  syncData,
  syncDataoffline,
} from "../../../assets/icons";
import CircularProgressBar from "../../Basic/CircularProgressBar";
import CircularProgressDone from "../../Basic/CircularProgressDone";
import {
  clearLocalStorage,
  getLocalStorage,
  STORAGE_KEYS,
} from "../../../helpers/common";
import { ROUTES_PATH } from "../../../routes";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ModalPopup from "../../Basic/Modalpopup/modalpopup";
import useToggleHook from "../../../hooks/useToggleHook";

export default function () {
  const [clockState, setClockState] = useState(
    new Date().toLocaleTimeString([], { timeStyle: "short" })
  );
  const [isDBSyncDone, setIsDBSyncDone] = useState(false);
  const history = useHistory();
  const userDetails = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_INFO);
  }, []);
  const { hideToggle, showToggle, toggle } = useToggleHook();
  const {
    toggle: dataSyncToggle,
    hideToggle: hideDataSyncToggle,
    showToggle: showDataSyncToggle,
  } = useToggleHook();

  useEffect(() => {
    setInterval(() => {
      const date = new Date();
      setClockState(date.toLocaleTimeString([], { timeStyle: "short" }));
    }, 1000);
  }, []);

  const getDBSyncStatus = () => {
    setTimeout(() => {
      hideDataSyncToggle();
    }, 2000);
  };

  const logoutHandler = () => {
    clearLocalStorage();
    history.replace(ROUTES_PATH.LOGIN);
  };

  const myaccountHandler = () => {
    showToggle();
  };

  const polling = {
    url: "",
    enabled: true,
    interval: 200000,
    timeout: 200000,
  };

  return (
    <Fragment>
      <Row className="d-none">
        <Col xs={"12"} className="logo-container">
          <div>
            <img
              src={sathibazarLogo}
              alt="satibazar_logo"
              title="Click here to Navigate to Landing Page"
            />
          </div>
          <Row className="timer-date-userdropdown">
            <Row>
              <Online polling={polling}>
                <img
                  className="sync-data"
                  src={syncData}
                  onClick={() => {
                    showDataSyncToggle();
                    getDBSyncStatus();
                  }}
                  alt="sync_data_online"
                  title="Sync Data"
                />
              </Online>
              <Offline polling={polling}>
                <img
                  className="sync-data"
                  src={syncDataoffline}
                  alt="sync_data_offline"
                  title="Sync Data"
                />
              </Offline>
            </Row>
            <Row>
              <span>{clockState}</span>
            </Row>
            <Row>
              <span>{new Date().toLocaleDateString()}</span>
            </Row>
            <Row className="user-profile-icon">
              <Dropdown className="text-capitalize">
                <Dropdown.Toggle>
                  <img src={userIcon} title="User Icon" alt="User Icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={myaccountHandler}>
                    my account
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logoutHandler}>logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Row>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={"12"} className="nav-links-container">
          {(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin"
            ? navItems
            : cafeItems
          ).map((nav, index) => (
            <NavLink
              key={index}
              className="nav-item"
              activeStyle={{ backgroundColor: "#06215f" }}
              to={nav.navLink}
            >
              <img
                className="nav-item-img"
                src={nav.icon}
                alt="nav-item-img"
                title="Naviagation Item"
              />
              <span
                style={{ position: "absolute", right: "5px", color: "white" }}
              >
                {nav.hotKey}
              </span>
              <span className="nav-item-name"> {nav.name}</span>
            </NavLink>
          ))}
        </Col>
      </Row>

      <ModalPopup
        isOpen={dataSyncToggle}
        modalTitle={`Database Sync : ${isDBSyncDone ? `Done` : "Please Wait"}`}
        onClose={hideDataSyncToggle}
      >
        <Fragment>
          <Container className="d-flex justify-content-center align-items-center">
            {!dataSyncToggle && <CircularProgressBar />}

            {dataSyncToggle && <CircularProgressDone />}
          </Container>
        </Fragment>
      </ModalPopup>

      <ModalPopup isOpen={toggle} modalTitle={"profile"} onClose={hideToggle}>
        <Fragment>
          <Row className="mb-2">
            <Col
              xs={"12"}
              className={"d-flex justify-content-center text-capitalize"}
            >
              <span>user name : </span>
              <span>
                {userDetails ? userDetails?.name || "Guest" : "Guest"}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={"12"} className={"d-flex justify-content-center"}>
              <span className="text-capitalize">
                email <span> : </span>
              </span>
              <span>
                {userDetails ? userDetails?.email || "guest" : "Guest"}
              </span>
            </Col>
          </Row>
        </Fragment>
      </ModalPopup>
    </Fragment>
  );
}
