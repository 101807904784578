import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";
import "./tableContainer.scss";
import removeIcon from "../../../assets/icons/close.svg";

type tableContainer = {
  columns: any;
  data: any;
  isGlobalFilter?: any;
  className?: any;
  handleClick?: any;
  PlaceholderValue: any;
  pageNav?: any;
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  PlaceholderValue,
}: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    // console.log("=============> value", value)
  }, 200);

  return (
    <Col className={"global-filter"}>
      <Form.Control
        type="search"
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={PlaceholderValue}
      />
      {/* close icon */}
      {/* <span className="close-icon" onClick={() => { setGlobalFilter("");setValue("") }}>
                x
            </span> */}
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  handleClick,
  isGlobalFilter,
  // isAddOptions,
  // isAddUserList,
  // isExcelUsed,
  // handleOrderClicks,
  // handleUserClick,
  // handleCustomerClick,
  // isAddCustList,
  // customPageSize,
  className,
  PlaceholderValue,
  pageNav = true,
}: // customPageSizeOptions,
// addText,
// isInsideFilter,
//refetchData
tableContainer) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  }: any = useTable(
    {
      columns,
      data,
      //   defaultColumn: { Filter: DefaultColumnFilter },
      //   initialState: {
      //     pageIndex: 0,
      //     pageSize: customPageSize,
      //     sortBy: [
      //       {
      //         desc: true,
      //       },
      //     ],
      //   },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  // console.log("products data list",data, columns);

  const generateSortingIndicator = (column: any) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event: any) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event: any) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  return (
    <Fragment>
      <Row>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            PlaceholderValue={PlaceholderValue}
          />
        )}
      </Row>
      <div className="table-responsive react-table ">
        <Table hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap ">
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th key={column.id}>
                    <div className="mb-2" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* {isInsideFilter && <Filter column={column} />} */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    className={
                      row.original.is_deleted === true ? "deleted-row" : ""
                    }
                    onClick={(): any => {
                      handleClick(row.original);
                    }}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {pageNav && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                title="Go to starting page"
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
                title="Previous Page"
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                title="Next Page"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                title="Go to last page"
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
