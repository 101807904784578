import React, { useState, useEffect, useMemo } from "react";
import Modal from "../../Basic/Modal";
import { productData } from "../../../data/dummy/productData";
import { inventory_db, fetchInventoryToDB } from "../../../data/indexedDB";
import SearchBar from "../../Basic/Search/SearchBar";
import BookData from "../../../data/dummy/Data.json";
import { toastNotifications } from "../../../helpers";
import axios from "axios";
import { Offline, Online } from "react-detect-offline";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import { getMethod, postMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { Button } from "react-bootstrap";
import Select from "react-select";
import BarCodeModal from "./BarCodeModal";
import "../BillDetails/styles.scss";
import TableContainer from "../../Basic/TableContainer/TableContainer";

export default function AddProductModal(props: any) {
  const {
    setIsAddProductModalOpen,
    isProductSearchable,
    productModalHeader,
    productAddedSuccessToast,
    setAddedProductList,
    isInventoryListLoading,
    inventoryListData,
    setProductSelectedFromItemCode,
    productSelectedFromItemCode,
    setCreateBill,
    setInventoryId,
    addedproductList,
    tdsRate,
    tcsCgstRate,
    tcsSgstRate,
    isCafeIsStoreBoolean,
  } = props;

  const {
    tdsStore,
    tdsCafe,
    tcsCgstStore,
    tcsCgstCafe,
    tcsSgstStore,
    tcsSgstCafe,
  } = isCafeIsStoreBoolean;

  const [addedReorderStockList, setAddedReorderStockList] = useState<any>([]);
  const [productSearchString, setProductSearchString] = useState("");
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [displayQty, setDisplayQty] = useState(1);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isBarCodeModalOpen, setIsBarCodeModalOpen] = useState(false);
  const [barCodeValue, setBarcodeValue] = useState("");
  const [productSelected, setProductSelected] = useState(() => {
    if (!isProductSearchable) {
      return productSelectedFromItemCode;
    } else {
      return {};
    }
  });
  interface Product {
    _id: string;
    batch_no: string;
    current_qty: number;
    customer_pay: number;
    expiry_date: string;
  }

  interface SelectedOption {
    value: string;
    label: string;
  }
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const [currentQty, setCurrentQty] = useState(1);
  let onlineStatus = navigator.onLine;
  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  useEffect(() => {
    setSelectedProductQuantity(1);
    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("storeadmin")) {
      if (addedReorderStockList.current_qty) {
        const a = addedReorderStockList.current_qty - 1;
        setCurrentQty(a);
      } else {
        const a = addedReorderStockList.quantity - 1;
        setCurrentQty(a);
      }
    }
    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin")) {
      if (
        productSelected.quantity_details &&
        productSelected.quantity_details[0]?.qty
      ) {
        const a =
          productSelected.quantity_details &&
          productSelected.quantity_details[0]?.qty - 1;
        setCurrentQty(a);
      } else {
        const a =
          productSelected.quantity_details &&
          productSelected.quantity_details[0]?.qty - 1;
        setCurrentQty(a);
      }
    }
  }, [addedReorderStockList, productSelected]);
  useEffect(() => {
    getProductsFromInventory();
  }, []);
  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "-";
  };

  const barcodeValFeild = (cell: any) => {
    return cell.value ? cell.value : "-";
  };

  const storeColumns = [
    {
      Header: "Category",
      accessor: "category",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand",
      accessor: "brand",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Product",
      accessor: (cellProps: any) => {
        const productValue = cellProps.product_name
          ? cellProps?.product_name?.length > 50
            ? cellProps?.product_name?.slice(0, 50) + "..."
            : cellProps?.product_name
          : cellProps?.name?.length > 50
          ? cellProps?.name?.slice(0, 50) + "..."
          : cellProps?.name;
        return productValue;
      },
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Bar Code",
      accessor: "barCode",
      Cell: (cellProps: any) => {
        const substrngVal = cellProps?.value?.substring(0, 20);
        const barcodeVal =
          cellProps?.value?.length > 20
            ? substrngVal.concat("...")
            : substrngVal;
        return (
          <>
            {substrngVal?.length > 20 ? (
              <span
                onClick={() => {
                  toggleContent();
                  setBarcodeValue(addedReorderStockList.barCode);
                }}
              >
                {barcodeVal}&nbsp;
                <span className="fw-bold">{"More"}</span>
              </span>
            ) : (
              <>
                <span>{barcodeVal}</span>
              </>
            )}
          </>
        );
      },
    },
    {
      Header: "Price/Unit(Rs)",
      accessor: (cellProps: any) => {
        const priceUnitVal = cellProps?.customer_pay
          ? cellProps?.customer_pay?.toFixed(2)
          : cellProps?.unitPrice
          ? cellProps?.unitPrice?.toFixed(2)
          : cellProps?.price?.toFixed(2) || "";
        const unitVal = cellProps?.unit ? cellProps?.unit : "1";
        return priceUnitVal == "" ? "-" : priceUnitVal + "/" + unitVal;
      },
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
  ];

  const Cafecolumns = [
    {
      Header: "Category",
      accessor: "category",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Product",
      accessor: (cellProps: any) => {
        const productValue = cellProps.product_name
          ? cellProps?.product_name?.length > 50
            ? cellProps?.product_name?.slice(0, 50) + "..."
            : cellProps?.product_name
          : cellProps?.name?.length > 50
          ? cellProps?.name?.slice(0, 50) + "..."
          : cellProps?.name;
        return productValue;
      },
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Price/Unit(Rs)",
      accessor: (cellProps: any) => {
        const priceUnitVal = cellProps?.customer_pay
          ? cellProps?.customer_pay?.toFixed(2)
          : cellProps?.unitPrice
          ? cellProps?.unitPrice?.toFixed(2)
          : cellProps?.price?.toFixed(2) || "";
        const unitVal = cellProps?.unit ? cellProps?.unit : "1";
        return priceUnitVal == "" ? "-" : priceUnitVal + "/" + unitVal;
      },
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
  ];

  const calculateSellerShare = (data: any, qty: any) => {
    const newPrice = Number(data?.price);
    const tdsAmt = tdsStore == true ? (newPrice * tdsRate) / 100 : 0;
    const sellerShare = newPrice - tdsAmt;
    const sellerShareWithQty = sellerShare * qty;
    const sellerShareObj = {
      sellerTDSonIT: tdsAmt?.toFixed(2),
      sellerShare: sellerShare?.toFixed(2),
      finalSellerShare: sellerShareWithQty?.toFixed(2),
    };

    return sellerShareObj;
  };

  const calculateStoreShare = (data: any, qty: any) => {
    const newPrice = Number(data?.storeMarginAmount);
    const tdsAmt = tdsStore == true ? (newPrice * tdsRate) / 100 : 0;
    const storeShare = newPrice - tdsAmt;
    const storeShareWithQty = storeShare * qty;
    const storeShareObj = {
      storeTDSonIT: tdsAmt?.toFixed(2),
      storeShare: storeShare?.toFixed(2),
      finalStoreShare: storeShareWithQty?.toFixed(2),
    };
    return storeShareObj;
  };
  const calculateCafe = (data: any, qty: any) => {
    const newPrice = Number(data?.discounted_price);
    const tdsAmt = tdsCafe == true ? (newPrice * tdsRate) / 100 : 0;
    const cafeShare = newPrice - tdsAmt;
    const cafeShareWithQty = cafeShare * qty;
    const cafeShareObj = {
      storeTDSonIT: tdsAmt?.toFixed(2),
      storeShare: cafeShare?.toFixed(2),
      finalStoreShare: cafeShareWithQty?.toFixed(2),
    };
    return cafeShareObj;
  };

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      let response;
      let qty_filter_data;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        response = await postMethod(
          API_END_POINTS.INVENTORY_UTILS_SEARCH,
          data
        );
        qty_filter_data =
          response.data &&
          response.data?.filter((qty: any) => !(qty.current_qty <= 0));
      } else {
        response = await getMethod(
          API_END_POINTS.GET_HOTCOOK_PRODUCTS_QTY.replace(
            ":state",
            details?.location?.state
          ).replace(":store_id", details.registeration_id)
        );
        qty_filter_data =
          response.data &&
          response.data?.filter(
            (qty: any) =>
              !(
                qty?.quantity_details.length == 0 ||
                (qty?.quantity_details && qty?.quantity_details[0]?.qty <= 0)
              )
          );
      }

      setInventoryProducts(
        qty_filter_data?.filter((ele: any) => {
          return (
            !ele?.isStoreDeleted &&
            !ele?.isSellerDeleted &&
            !ele?.isCategoryDeleted
          );
        })
      );
    } catch (err) {}
  };

  // when user click add bill
  const onAddBillHandler = () => {
    if (isStore) {
      if (Object.keys(addedReorderStockList)?.length != 0) {
        if (Number(selectedProductQuantity) > 0) {
          setAddedProductList((prevArray: any) => {
            const existingProudctId = prevArray.findIndex(
              (item: any) =>
                item._id === addedReorderStockList._id ||
                item.reorderedInventoryRef === addedReorderStockList._id
            );

            if (existingProudctId !== -1) {
              const updatedArray = [...prevArray];
              const existingQty = !isNaN(
                Number(updatedArray[existingProudctId]?.quantitySelected)
              )
                ? Number(updatedArray[existingProudctId]?.quantitySelected)
                : Number(updatedArray[existingProudctId]?.qty);
              const newQty = Number(selectedProductQuantity);
              const addedQty = existingQty + newQty;
              // updatedArray[existingProudctId].quantitySelected = addedQty;

              updatedArray[existingProudctId] = {
                ...updatedArray[existingProudctId],
                quantitySelected: addedQty,
                qty: addedQty,
              };

              return updatedArray;
            } else {
              return [
                ...prevArray,
                {
                  ...addedReorderStockList,
                  quantitySelected: selectedProductQuantity,
                  currentQty:
                    getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) === "storeadmin" ||
                    getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) === "cafeAdmin"
                      ? currentQty
                      : "N/A",
                },
              ];
            }
            //
          });

          setCreateBill((prv: any) => {
            const existingProudctId = prv.add_product.findIndex(
              (item: any) =>
                item._id === addedReorderStockList._id ||
                item.reorderedInventoryRef === addedReorderStockList._id
            );
            if (existingProudctId !== -1) {
              const updatedProductList = [...prv.add_product];

              // const existingQty = Number(
              //   updatedProductList[existingProudctId].quantitySelected
              // );

              const existingQty = !isNaN(
                Number(updatedProductList[existingProudctId]?.quantitySelected)
              )
                ? Number(
                    updatedProductList[existingProudctId]?.quantitySelected
                  )
                : Number(updatedProductList[existingProudctId]?.qty);

              const newQty = Number(selectedProductQuantity);
              const addedQty = existingQty + newQty;
              updatedProductList[existingProudctId] = {
                ...updatedProductList[existingProudctId],
                quantitySelected: addedQty,
                qty: addedQty,
              };
              // updatedProductList[existingProudctId].quantitySelected = addedQty;
              const finalSellerPrice = Number(
                addedReorderStockList?.finalSellerPrice
              );

              const sellerShareObj = calculateSellerShare(
                addedReorderStockList,
                addedQty
              );
              const storeShareObj = calculateStoreShare(
                addedReorderStockList,
                addedQty
              );

              return {
                ...prv,
                add_product: updatedProductList,
                total_seller_price_with_qty: Number(
                  finalSellerPrice * addedQty
                ),
                storeMarginAmount: addedReorderStockList?.storeMarginAmount,
                storeMarginAmountWithQty:
                  (Number(addedReorderStockList?.storeMarginAmount) +
                    Number(addedReorderStockList?.storeMarginAmount) * 0.18) *
                  Number(addedQty),
                product_total:
                  Number(addedReorderStockList?.customer_pay) *
                  Number(addedQty),
                ...sellerShareObj,
                ...storeShareObj,
              };
            } else {
              const finalSellerPrice = Number(
                addedReorderStockList?.finalSellerPrice
              );

              const sellerShareObj = calculateSellerShare(
                addedReorderStockList,
                selectedProductQuantity
              );

              const storeShareObj = calculateStoreShare(
                addedReorderStockList,
                selectedProductQuantity
              );

              return {
                ...prv,
                add_product: [
                  ...prv.add_product,
                  {
                    ...addedReorderStockList,
                    quantitySelected: selectedProductQuantity,
                    currentQty: currentQty,
                    total_seller_price_with_qty: Number(
                      finalSellerPrice * selectedProductQuantity
                    ),
                    storeMarginAmount: Number(
                      addedReorderStockList?.storeMarginAmount
                    ),
                    storeMarginAmountWithQty:
                      (Number(addedReorderStockList?.storeMarginAmount) +
                        Number(addedReorderStockList?.storeMarginAmount) *
                          0.18) *
                      Number(selectedProductQuantity),
                    product_total:
                      Number(addedReorderStockList?.customer_pay) *
                      Number(selectedProductQuantity),
                    ...sellerShareObj,
                    ...storeShareObj,
                  },
                ],
              };
            }
          });

          setIsAddProductModalOpen(false);
          productAddedSuccessToast("Product Added");
        } else {
          setIsAddProductModalOpen(false);
          toastNotifications.error("Product Qty should be greater than 0");
        }
      }
    } else {
      if (Object.keys(productSelected).length != 0) {
        if (Number(selectedProductQuantity) > 0) {
          setAddedProductList((prevArray: any) => {
            const existingProductId = prevArray.findIndex(
              (item: any) => item?._id === productSelected._id
            );

            if (existingProductId !== -1) {
              const updatedProdcut = [...prevArray];
              const existingQty = Number(
                updatedProdcut[existingProductId].quantitySelected
              );
              const newQty = Number(selectedProductQuantity);
              const addedQty = existingQty + newQty;
              //updatedProdcut[existingProductId].quantitySelected = addedQty;
              updatedProdcut[existingProductId] = {
                ...updatedProdcut[existingProductId],
                quantitySelected: addedQty,
              };
              return updatedProdcut;
            } else {
              return [
                ...prevArray,
                {
                  ...productSelected,
                  quantitySelected: selectedProductQuantity,
                  currentQty:
                    getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin" ||
                    "cafeAdmin"
                      ? currentQty
                      : "N/A",
                },
              ];
            }
          });
          // add products to create bill
          setCreateBill((prv: any) => {
            const existingProudctId = prv.add_product.findIndex(
              (item: any) => item?._id === productSelected._id
            );

            if (existingProudctId !== -1) {
              const updatedProductList = [...prv.add_product];

              const existingQty = Number(
                updatedProductList[existingProudctId].quantitySelected
              );
              const newQty = Number(selectedProductQuantity);
              const addedQty = existingQty + newQty;
              updatedProductList[existingProudctId].quantitySelected = addedQty;
              const gstAmount =
                (Number(productSelected?.discounted_price) *
                  Number(productSelected?.gst)) /
                100;
              const sellerAmount =
                Number(productSelected?.discounted_price) + gstAmount;
              const finalAmount = sellerAmount * addedQty;
              const cafeCalculations = calculateCafe(productSelected, addedQty);

              return {
                ...prv,
                add_product: updatedProductList,
                total_seller_price_with_qty: finalAmount,
                product_total:
                  Number(productSelected?.customer_pay) * Number(addedQty),
                ...cafeCalculations,
              };
            } else {
              const gstAmount =
                (Number(productSelected?.discounted_price) *
                  Number(productSelected?.gst)) /
                100;
              const sellerAmount =
                Number(productSelected?.discounted_price) + gstAmount;
              const finalAmount = sellerAmount * selectedProductQuantity;
              const cafeCalculations = calculateCafe(
                productSelected,
                selectedProductQuantity
              );
              productAddedSuccessToast("Product Added");
              return {
                ...prv,
                add_product: [
                  ...prv.add_product,
                  {
                    ...productSelected,
                    quantitySelected: selectedProductQuantity,
                    currentQty: currentQty,
                    total_seller_price_with_qty: finalAmount,
                    product_total:
                      Number(productSelected?.customer_pay) *
                      Number(selectedProductQuantity),
                    ...cafeCalculations,
                  },
                ],
              };
            }
          });

          // close the modal popup
          setIsAddProductModalOpen(false);
          // show the toast message
        } else {
          setIsAddProductModalOpen(false);
          toastNotifications.error("Product Qty should be greater than 0");
        }
      }
    }
  };
  const options = productSelected?.ReorderedInventoryData?.filter(
    (product: Product) => product.current_qty > 0
  )?.map((product: Product) => {
    const formattedDate = new Date(product.expiry_date).toLocaleDateString();
    return {
      value: product._id,
      label: `Stock: ${product.batch_no} Quantity: ${
        product.current_qty
      } Price: ${product.customer_pay?.toFixed(2)} Exp Date: ${formattedDate}`,
    };
  });
  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected);
    setInventoryId(productSelected._id);
    if (selected != null) {
      const filteredSelectedData: any =
        productSelected?.ReorderedInventoryData.filter((ele: any) => {
          return ele._id === selected.value;
        });
      setAddedReorderStockList(filteredSelectedData && filteredSelectedData[0]);
      setDisplayQty(
        filteredSelectedData[0]?.current_qty ||
          filteredSelectedData[0]?.quantity
      );
    } else {
      setAddedReorderStockList(productSelected);
      setDisplayQty(productSelected?.current_qty || productSelected?.quantity);
    }
  };
  const toggleContent = () => {
    setIsBarCodeModalOpen(true);
  };
  const handleClick = () => {};

  return (
    <Modal
      header={productModalHeader}
      width={"80%"}
      setOpenModal={setIsAddProductModalOpen}
    >
      {/* {productSearchString} */}
      {!isInventoryListLoading ? (
        <>
          {isProductSearchable && (
            <>
              <div className="d-flex modalAlign gap-3 mb-3">
                <div className="w-100">
                  <SearchBar
                    placeholder="Enter product name"
                    listData={
                      inventoryProducts.length !== 0 && inventoryProducts
                    }
                    setValue={setProductSelected}
                    setDisplayQty={setDisplayQty}
                    productSelected={productSelected}
                    setAddedReorderStockList={setAddedReorderStockList}
                    setSelectedOption={setSelectedOption}
                  />
                </div>
                {isStore && (
                  <div className="w-100">
                    <Select
                      className=""
                      value={selectedOption}
                      onChange={handleSelectChange}
                      options={options}
                      isSearchable={true}
                      placeholder="Select Stock"
                      isClearable={true}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <TableContainer
            columns={isStore ? storeColumns : Cafecolumns}
            data={isStore ? [addedReorderStockList] : [productSelected]}
            isGlobalFilter={false}
            handleClick={handleClick}
            PlaceholderValue={""}
            pageNav={false}
          />
          <div className="addproduct-container">
            {isStore
              ? Object.keys(addedReorderStockList).length != 0 && (
                  <div>
                    <div className="added-product-quantity">
                      <span>Quantity</span>
                      <div
                        onClick={() => {
                          if (selectedProductQuantity >= 2) {
                            setSelectedProductQuantity(
                              selectedProductQuantity - 1
                            );
                          }
                          if (selectedProductQuantity > 1) {
                            setCurrentQty(currentQty + 1);
                          }
                          if (selectedProductQuantity >= 0) {
                            addedReorderStockList.quantity =
                              parseInt(addedReorderStockList.quantity) + 1;
                          }
                        }}
                      >
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          disabled={addedReorderStockList?.current_qty <= 0}
                          title="Decrease Quantity"
                        >
                          -
                        </button>
                      </div>
                      <input
                        style={{ width: "10%" }}
                        onChange={(e: any) => {
                          if (displayQty < Number(e.target.value)) {
                            e.target.value = 1;
                          } else {
                            setSelectedProductQuantity(Number(e.target.value));
                            // setCurrentQty(currentQty - Number(e.target.value));
                          }
                        }}
                        value={selectedProductQuantity}
                        min={1}
                        max={100}
                        type={"number"}
                        className="table-row-item-input"
                      />
                      <div>
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          onClick={() => {
                            setSelectedProductQuantity(
                              selectedProductQuantity + 1
                            );
                            if (selectedProductQuantity >= 0) {
                              setCurrentQty(currentQty - 1);
                            }
                            if (selectedProductQuantity >= 0) {
                              addedReorderStockList.quantity =
                                parseInt(addedReorderStockList.quantity) - 1;
                            }
                          }}
                          disabled={selectedProductQuantity >= displayQty}
                          title="Increase Quatity"
                        >
                          +
                        </button>
                      </div>

                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "storeadmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {addedReorderStockList?.current_qty <= 0
                              ? "Out Of Stock"
                              : `${displayQty}/Left`}
                          </span>
                        </div>
                      )}
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "cafeAdmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {addedReorderStockList?.quantity_details.length == 0
                              ? "Out Of Stock"
                              : `${displayQty}/Left`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              : Object.keys(productSelected).length != 0 && (
                  <div>
                    <div className="added-product-quantity">
                      <span>Quantity</span>
                      <div
                        onClick={() => {
                          if (selectedProductQuantity >= 2) {
                            setSelectedProductQuantity(
                              selectedProductQuantity - 1
                            );
                          }
                          if (selectedProductQuantity > 1) {
                            setCurrentQty(currentQty + 1);
                          }
                          if (selectedProductQuantity >= 0) {
                            productSelected.quantity =
                              parseInt(productSelected.quantity) + 1;
                          }
                        }}
                      >
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          title="Decrease Quatity"
                          disabled={productSelected?.current_qty <= 0}
                        >
                          -
                        </button>
                      </div>
                      <input
                        style={{ width: "10%" }}
                        onChange={(e: any) => {
                          if (displayQty < Number(e.target.value)) {
                            e.target.value = 1;
                          } else {
                            setSelectedProductQuantity(Number(e.target.value));
                          }
                        }}
                        value={selectedProductQuantity}
                        min={1}
                        max={100}
                        type={"number"}
                        className="table-row-item-input"
                      />
                      <div>
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          onClick={() => {
                            setSelectedProductQuantity(
                              selectedProductQuantity + 1
                            );
                            if (selectedProductQuantity >= 0) {
                              setCurrentQty(currentQty - 1);
                            }
                            if (selectedProductQuantity >= 0) {
                              productSelected.quantity =
                                parseInt(productSelected.quantity) - 1;
                            }
                          }}
                          title="Increase Quatity"
                          disabled={selectedProductQuantity >= displayQty}
                        >
                          +
                        </button>
                      </div>

                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "storeadmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {productSelected?.current_qty <= 0
                              ? "Out Of Stock"
                              : `${displayQty}/Left`}
                          </span>
                        </div>
                      )}
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "cafeAdmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {productSelected?.quantity_details.length == 0
                              ? "Out Of Stock"
                              : `${displayQty}/Left`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
          </div>

          <Button
            className="add-to-bill-buttons"
            disabled={
              isStore
                ? addedReorderStockList.current_qty <= 0
                : productSelected.current_qty <= 0
            }
            onClick={onAddBillHandler}
            title="Add to Bill"
          >
            Add to Bill
          </Button>
        </>
      ) : (
        <div> Loading </div>
      )}
      {isBarCodeModalOpen && (
        <BarCodeModal
          barcode={barCodeValue}
          setIsBarCodeModalOpen={setIsBarCodeModalOpen}
        />
      )}
    </Modal>
  );
}
